import React, { useState, useEffect } from "react";
import styled from "styled-components";

const PlayersContainer = styled.div`
  .search-bar {
    margin-bottom: 20px;
    input {
      width: 100%;
      padding: 10px;
      border: none;
      border-radius: 5px;
      background-color: #2e2e3d;
      color: white;
    }
  }
  
  .players-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 15px;
    .player-card {
      background-color: #2e2e3d;
      border-radius: 8px;
      padding: 15px;
      cursor: pointer;
      transition: transform 0.2s;
      &:hover {
        transform: translateY(-5px);
        box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
      }
      h3 {
        color: #007bff;
        margin-bottom: 5px;
      }
      p {
        color: #aaa;
        font-size: 0.9rem;
        margin: 5px 0;
      }
    }
  }
  
  .player-stats {
    margin-top: 20px;
    background-color: #2e2e3d;
    border-radius: 8px;
    padding: 15px;
    h3 {
      color: #007bff;
      margin-bottom: 10px;
    }
    .stats-grid {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
      gap: 10px;
      .stat-item {
        background-color: #343a40;
        padding: 10px;
        border-radius: 5px;
        .stat-label {
          font-size: 0.8rem;
          color: #aaa;
        }
        .stat-value {
          font-size: 1.2rem;
          color: white;
          font-weight: bold;
        }
      }
    }
  }
`;

const Players = ({ supabase, league_id }) => {
  const [players, setPlayers] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredPlayers, setFilteredPlayers] = useState([]);
  const [selectedPlayerStats, setSelectedPlayerStats] = useState(null);

  useEffect(() => {
    const fetchPlayers = async () => {
      const { data, error } = await supabase.from("nfl_players").select("*");
      if (!error) setPlayers(data || []);
    };
    fetchPlayers();
  }, [supabase]);

  const handleSearch = (e) => {
    const query = e.target.value.toLowerCase();
    setSearchQuery(query);
    if (query.trim() === "") {
      setFilteredPlayers([]);
      return;
    }
    const filtered = players.filter((p) =>
      p.name.toLowerCase().includes(query)
    );
    setFilteredPlayers(filtered);
  };

  const handlePlayerClick = async (playerId) => {
    const { data, error } = await supabase
      .from("nfl_playerstats")
      .select("*")
      .eq("player_id", playerId);
    if (!error) setSelectedPlayerStats(data);
  };

  return (
    <PlayersContainer>
      <h2>Players</h2>
      <div className="search-bar">
        <input
          type="text"
          placeholder="Search players..."
          value={searchQuery}
          onChange={handleSearch}
        />
      </div>

      <div className="players-grid">
        {(searchQuery ? filteredPlayers : players).map((player) => (
          <div
            key={player.player_id}
            className="player-card"
            onClick={() => handlePlayerClick(player.player_id)}
          >
            <h3>{player.name}</h3>
            <p>Position: {player.position}</p>
            <p>Team: {player.team}</p>
          </div>
        ))}
      </div>

      {selectedPlayerStats && (
        <div className="player-stats">
          <h3>Player Stats</h3>
          <div className="stats-grid">
            {Object.entries(selectedPlayerStats[0] || {}).map(
              ([key, value]) =>
                key !== "player_id" && (
                  <div key={key} className="stat-item">
                    <div className="stat-label">{key.replace(/_/g, " ")}</div>
                    <div className="stat-value">{value}</div>
                  </div>
                )
            )}
          </div>
        </div>
      )}
    </PlayersContainer>
  );
};

export default Players;
