import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';

// Styled components
const VerificationWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background: url('./images/vintagefb.png') no-repeat center center/cover;
`;

const VerificationBox = styled.div`
    background-color: rgba(0, 0, 0, 0.8);
    padding: 30px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
    text-align: center;
    width: 100%;
    max-width: 400px;
`;

const Title = styled.h1`
    color: #fff;
    margin-bottom: 20px;
    font-size: 28px;
    text-align: center;
    border-bottom: 2px solid #007bff;
    padding-bottom: 10px;
`;

const VerificationMessage = styled.p`
    color: #ddd;
    font-size: 16px;
    margin-top: 20px;
`;

const EmailVerification = () => {
    const [message, setMessage] = useState('');
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        const verifyEmail = async () => {
            const queryParams = new URLSearchParams(location.search);
            const token = queryParams.get('token');

            console.log('Verifying email with token:', token); // Log the token being used

            if (token) {
                try {
                    // Update to the production URL
                    const response = await fetch(`https://www.milkysports.com/api/verify?token=${token}`, {
                        method: 'GET',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                    });
                    
                    const data = await response.json();
                    console.log('Response from server:', data); // Log the server response
                    
                    if (response.ok) {
                        setMessage('You have successfully verified your account! Redirecting to login...');
                        setTimeout(() => {
                            navigate('/login'); // Change to your actual login route
                        }, 3000);
                    } else {
                        setMessage(data.message || 'Invalid or expired token. Please try again.');
                    }
                } catch (error) {
                    console.error('Error during email verification:', error);
                    setMessage(`An error occurred. Please try again. ${JSON.stringify(error)}`);
                }
            } else {
                setMessage('No verification token provided.');
            }
        };

        verifyEmail();
    }, [location.search, navigate]);

    return (
        <VerificationWrapper>
            <VerificationBox>
                <Title>Email Verification</Title>
                <VerificationMessage>{message}</VerificationMessage>
            </VerificationBox>
        </VerificationWrapper>
    );
};

export default EmailVerification;
