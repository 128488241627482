import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { FiUserPlus } from "react-icons/fi";
import { createClient } from "@supabase/supabase-js";
import { useUser } from "../UserContext";

// Import tab components
import Overview from "./Overview";
import Teams from "./Teams";
import Players from "./Players";
import Standings from "./Standings";
import Matchups from "./Matchups";
import WaiverWire from "./WaiverWire";
import Draft from "./Draft";
import Chat from "./Chat";
import Settings from "./Settings";

// Initialize Supabase client
const supabase = createClient(
  process.env.REACT_APP_SUPABASE_URL,
  process.env.REACT_APP_SUPABASE_ANON_KEY
);

// Styled components for the main container
const Container = styled.div`
  width: 95%;
  margin: 20px auto;
  font-family: Arial, sans-serif;
  color: #fff;
  padding: 20px;
  border-radius: 8px;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: linear-gradient(90deg, #007bff, #6610f2);
  color: #fff;
  padding: 20px;
  border-radius: 8px;
  margin-bottom: 20px;
  
  h1 {
    margin: 0;
    font-size: 2rem;
  }
  
  .league-details {
    font-size: 1rem;
    color: #ddd;
  }
`;

const InviteButton = styled.button`
  background-color: #28a745;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.3s ease;
  
  &:hover {
    background-color: #218838;
  }
`;

const TabsContainer = styled.div`
  display: flex;
  justify-content: space-around;
  background-color: #343a40;
  padding: 10px;
  border-radius: 8px;
`;

const Tab = styled.button`
  flex: 1;
  text-align: center;
  background: none;
  border: none;
  color: #aaa;
  padding: 10px;
  cursor: pointer;
  font-size: 1rem;
  transition: color 0.3s ease;
  
  &.active {
    color: #fff;
    font-weight: bold;
    border-bottom: 3px solid #007bff;
  }
  
  &:hover {
    color: #fff;
  }
`;

const ContentContainer = styled.div`
  margin-top: 20px;
  background-color: #1e1e2f;
  border-radius: 8px;
  padding: 20px;
`;

const FantasyLeagueManagement = ({ league }) => {
  const { user } = useUser();
  // Assume the passed league object contains a property league_id
  const league_id = league ? league.league_id : null;

  // eslint-disable-next-line no-unused-vars
  const [leagueData, setLeagueData] = useState(null);
  const [leagueMembers, setLeagueMembers] = useState([]);
  const [inviteLink, setInviteLink] = useState("");
  const [leagueName, setLeagueName] = useState("Loading...");
  const [sport, setSport] = useState("Loading...");

  // Active tab state – initial tab read from localStorage if available
  const [activeTab, setActiveTab] = useState(
    localStorage.getItem("activeTab") || "overview"
  );

  // Fetch league data on mount
  useEffect(() => {
    if (!league_id) return;
    const fetchLeagueData = async () => {
      try {
        // Rename destructured variable to avoid shadowing setLeagueData
        const { data: leagueInfo, error } = await supabase
          .from("fantasy_leagues")
          .select("*, sport:sport_id(sport_name)")
          .eq("league_id", Number(league_id))
          .single();
        if (error) throw error;
        setLeagueData(leagueInfo);
        setLeagueName(leagueInfo.league_name || "Unknown League");
        setSport(leagueInfo.sport?.sport_name || "Unknown Sport");

        // Generate invite link
        const baseUrl = window.location.origin;
        setInviteLink(`${baseUrl}/join-league/${league_id}`);

        // Fetch league members
        const { data: membersData, error: membersError } = await supabase
          .from("league_members")
          .select("*, profiles(*)")
          .eq("league_id", Number(league_id));
        if (membersError) throw membersError;
        setLeagueMembers(membersData);
      } catch (error) {
        console.error("Error fetching league data:", error.message);
      }
    };
    fetchLeagueData();
  }, [league_id]);

  const switchTab = (tab) => {
    setActiveTab(tab);
    localStorage.setItem("activeTab", tab);
  };

  const copyInviteLink = () => {
    navigator.clipboard.writeText(inviteLink);
    alert("Invitation link copied to clipboard!");
  };

  return (
    <Container>
      <Header>
        <div>
          <h1>{leagueName}</h1>
          <div className="league-details">
            <p>Sport: {sport}</p>
          </div>
        </div>
        <InviteButton onClick={copyInviteLink}>
          <FiUserPlus style={{ marginRight: "5px" }} />
          Invite Players
        </InviteButton>
      </Header>

      <TabsContainer>
        <Tab key="overview" className={activeTab === "overview" ? "active" : ""} onClick={() => switchTab("overview")}>
          Overview
        </Tab>
        <Tab key="teams" className={activeTab === "teams" ? "active" : ""} onClick={() => switchTab("teams")}>
          Teams
        </Tab>
        <Tab key="players" className={activeTab === "players" ? "active" : ""} onClick={() => switchTab("players")}>
          Players
        </Tab>
        <Tab key="standings" className={activeTab === "standings" ? "active" : ""} onClick={() => switchTab("standings")}>
          Standings
        </Tab>
        <Tab key="matchups" className={activeTab === "matchups" ? "active" : ""} onClick={() => switchTab("matchups")}>
          Matchups
        </Tab>
        <Tab key="waiver" className={activeTab === "waiver" ? "active" : ""} onClick={() => switchTab("waiver")}>
          Waiver Wire
        </Tab>
        <Tab key="draft" className={activeTab === "draft" ? "active" : ""} onClick={() => switchTab("draft")}>
          Draft
        </Tab>
        <Tab key="chat" className={activeTab === "chat" ? "active" : ""} onClick={() => switchTab("chat")}>
          Chat
        </Tab>
        <Tab key="settings" className={activeTab === "settings" ? "active" : ""} onClick={() => switchTab("settings")}>
          Settings
        </Tab>
      </TabsContainer>

      <ContentContainer>
        {activeTab === "overview" && <Overview leagueName={leagueName} />}
        {activeTab === "teams" && (
          <Teams
            supabase={supabase}
            league_id={league_id}
            user={user}
            leagueMembers={leagueMembers}
          />
        )}
        {activeTab === "players" && (
          <Players supabase={supabase} league_id={league_id} />
        )}
        {activeTab === "standings" && (
          <Standings supabase={supabase} league_id={league_id} />
        )}
        {activeTab === "matchups" && (
          <Matchups supabase={supabase} league_id={league_id} />
        )}
        {activeTab === "waiver" && (
          <WaiverWire supabase={supabase} league_id={league_id} />
        )}
        {activeTab === "draft" && (
          <Draft
            supabase={supabase}
            league_id={league_id}
            leagueMembers={leagueMembers}
            user={user}
          />
        )}
        {activeTab === "chat" && (
          <Chat supabase={supabase} league_id={league_id} user={user} />
        )}
        {activeTab === "settings" && <Settings leagueName={leagueName} />}
      </ContentContainer>
    </Container>
  );
};

export default FantasyLeagueManagement;
