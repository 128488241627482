import React, { useState, useEffect, useCallback } from "react";
import styled from "styled-components";

const DraftContainer = styled.div`
  .draft-controls {
    display: flex;
    gap: 10px;
    margin-bottom: 20px;
    input {
      padding: 10px;
      border: none;
      border-radius: 5px;
      background-color: #2e2e3d;
      color: white;
    }
    button {
      padding: 10px 15px;
      background-color: #007bff;
      color: white;
      border: none;
      border-radius: 5px;
      cursor: pointer;
      &:hover {
        background-color: #0056b3;
      }
    }
  }
  .draft-board {
    display: flex;
    flex-direction: column;
    gap: 20px;
    .draft-status {
      background-color: #2e2e3d;
      border-radius: 8px;
      padding: 15px;
      text-align: center;
      h3 {
        color: #007bff;
        margin-bottom: 10px;
        font-size: 24px;
      }
      p {
        font-size: 18px;
        color: #fff;
      }
    }
    .draft-columns {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 20px;
    }
    .available-players,
    .drafted-players {
      background-color: #2e2e3d;
      border-radius: 8px;
      padding: 15px;
      max-height: 500px;
      overflow-y: auto;
      h3 {
        color: #007bff;
        margin-bottom: 10px;
      }
      input {
        width: 100%;
        padding: 10px;
        margin-bottom: 15px;
        border: none;
        border-radius: 5px;
        background-color: #1e1e2d;
        color: white;
      }
      ul {
        list-style: none;
        padding: 0;
        li {
          padding: 12px;
          margin-bottom: 8px;
          background-color: #343a40;
          border-radius: 5px;
          cursor: pointer;
          transition: background-color 0.2s;
          display: flex;
          justify-content: space-between;
          &:hover {
            background-color: #007bff;
          }
        }
      }
    }
  }
  .draft-summary {
    h3 {
      color: #007bff;
      margin-bottom: 20px;
      text-align: center;
    }
    h4 {
      margin-bottom: 15px;
    }
    table {
      width: 100%;
      border-collapse: collapse;
      th,
      td {
        padding: 12px;
        text-align: left;
        border-bottom: 1px solid #444;
      }
      th {
        background-color: #2e2e3d;
        color: #007bff;
      }
      tr:nth-child(even) {
        background-color: #2a2a3a;
      }
      tr:hover {
        background-color: #3a3a4a;
      }
    }
  }
`;

const Draft = ({ supabase, league_id, leagueMembers, user }) => {
  const [draftScheduled, setDraftScheduled] = useState(false);
  const [draftInProgress, setDraftInProgress] = useState(false);
  const [draftComplete, setDraftComplete] = useState(false);
  const [draftDate, setDraftDate] = useState("");
  const [draftTime, setDraftTime] = useState("");
  const [draftOrder, setDraftOrder] = useState([]);
  const [currentTeamIndex, setCurrentTeamIndex] = useState(0);
  const [currentRound, setCurrentRound] = useState(1);
  const [availablePlayers, setAvailablePlayers] = useState([]);
  const [draftedPlayers, setDraftedPlayers] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [userHasTeam, setUserHasTeam] = useState(false);
  const [allTeams, setAllTeams] = useState([]);
  const totalRounds = 15;

  // Timer and pick state
  const [timeLeft, setTimeLeft] = useState(60);
  const [timerActive, setTimerActive] = useState(false);
  const [isPicking, setIsPicking] = useState(false);

  // Check if user has a team
  useEffect(() => {
    if (!league_id || !user) return;
    const checkUserTeam = async () => {
      const { data, error } = await supabase
        .from("league_teams")
        .select("*")
        .eq("league_id", league_id)
        .eq("user_id", user.id)
        .maybeSingle();
      setUserHasTeam(!error && data ? true : false);
      // Also fetch all teams for the league
      const { data: allTeamsData, error: allTeamsError } = await supabase
        .from("league_teams")
        .select("*")
        .eq("league_id", league_id);
      if (!allTeamsError && allTeamsData) {
        console.log("All teams data:", allTeamsData);
        setAllTeams(allTeamsData);
      }
    };
    checkUserTeam();
  }, [league_id, supabase, user]);

  // COMPLETE DRAFT
  const completeDraft = useCallback(async () => {
    try {
      const { error } = await supabase
        .from("league_drafts")
        .update({ status: "Completed" })
        .eq("league_id", league_id);
      if (error) throw error;
      setDraftInProgress(false);
      setDraftComplete(true);
      setTimerActive(false);
      alert("Draft completed successfully!");
    } catch (error) {
      console.error("Error completing draft:", error.message);
    }
  }, [league_id, supabase]);

  // DRAFT A PLAYER (Manual or Auto Pick)
  const draftPlayer = useCallback(async (playerId) => {
    // Prevent duplicate picks
    if (!draftInProgress || isPicking) return;
    setIsPicking(true);
    try {
      // First check if player is already drafted
      const { data: existingPlayer } = await supabase
        .from("league_players")
        .select("*")
        .eq("league_id", Number(league_id))
        .eq("player_id", Number(playerId))
        .single();
      if (existingPlayer) {
        console.log("Player already drafted:", existingPlayer);
        // Remove from availablePlayers to prevent future attempts
        setAvailablePlayers((prev) =>
          prev.filter((p) => p.player_id !== playerId)
        );
        setIsPicking(false);
        // Move to next pick without drafting
        if (currentTeamIndex + 1 >= draftOrder.length) {
          completeDraft();
        } else {
          setCurrentTeamIndex(currentTeamIndex + 1);
          setTimeLeft(60);
        }
        return;
      }
      const player = availablePlayers.find((p) => p.player_id === playerId);
      if (!player) {
        console.log("Player not available in availablePlayers list");
        setIsPicking(false);
        return;
      }
      const currentPick = draftOrder[currentTeamIndex];
      // Check if it's the user's turn or if auto-pick is allowed
      const isUsersTurn = currentPick && currentPick.userId === user.id;
      const isCommissioner = user.id === league_id;
      if (!isUsersTurn && !isCommissioner) {
        alert("It's not your turn to pick.");
        setIsPicking(false);
        return;
      }
      // Find the team in allTeams
      const team = allTeams.find((t) => t.id === currentPick.teamId);
      if (!team) {
        console.error("Team not found in allTeams:", currentPick.teamId);
        alert("Error: Team not found. Please try again or contact support.");
        setIsPicking(false);
        return;
      }
      const { error } = await supabase.from("league_players").insert([
        {
          league_id: Number(league_id),
          team_id: team.id,
          player_id: Number(playerId),
          draft_position: currentTeamIndex,
          draft_round: currentPick.round,
        },
      ]);
      if (error) {
        console.error("Insert error details:", error);
        if (error.code === "23505") {
          console.log("Player already drafted, moving to next pick");
          setAvailablePlayers((prev) =>
            prev.filter((p) => p.player_id !== playerId)
          );
          if (currentTeamIndex + 1 >= draftOrder.length) {
            completeDraft();
          } else {
            setCurrentTeamIndex(currentTeamIndex + 1);
            setTimeLeft(60);
          }
          setIsPicking(false);
          return;
        }
        throw error;
      }
      setDraftedPlayers((prev) => [
        ...prev,
        {
          player,
          team: currentPick.teamName,
          round: currentPick.round,
          pick: currentPick.overall,
        },
      ]);
      setAvailablePlayers((prev) =>
        prev.filter((p) => p.player_id !== playerId)
      );
      if (currentTeamIndex + 1 >= draftOrder.length) {
        completeDraft();
      } else {
        setCurrentTeamIndex(currentTeamIndex + 1);
        setTimeLeft(60);
      }
    } catch (error) {
      console.error("Error drafting player:", error.message);
      alert("Failed to draft player. Please try again.");
    } finally {
      setIsPicking(false);
    }
  }, [
    availablePlayers,
    currentTeamIndex,
    draftInProgress,
    draftOrder,
    league_id,
    supabase,
    completeDraft,
    user.id,
    allTeams,
    isPicking,
  ]);

  // SCHEDULE DRAFT
  const scheduleDraft = async () => {
    try {
      const draftDateTimeString = `${draftDate}T${draftTime}:00`;
      const draftDateTime = new Date(draftDateTimeString);
      if (isNaN(draftDateTime.getTime())) {
        alert("Invalid date or time format");
        return;
      }
      const { data: existingDraft } = await supabase
        .from("league_drafts")
        .select("*")
        .eq("league_id", Number(league_id))
        .maybeSingle();
      if (existingDraft) {
        const { error: updateError } = await supabase
          .from("league_drafts")
          .update({
            draft_time: draftDateTime.toISOString(),
            status: "Scheduled",
          })
          .eq("id", existingDraft.id);
        if (updateError) throw updateError;
      } else {
        const { error: insertError } = await supabase
          .from("league_drafts")
          .insert({
            league_id: Number(league_id),
            draft_time: draftDateTime.toISOString(),
            status: "Scheduled",
            current_round: 1,
          });
        if (insertError) throw insertError;
      }
      setDraftScheduled(true);
      setDraftTime(draftDateTime.toISOString());
      alert("Draft scheduled successfully!");
    } catch (error) {
      console.error("Error scheduling draft:", error.message);
      alert("Error scheduling draft: " + error.message);
    }
  };

  // START DRAFT
  const startDraft = async () => {
    if (!draftScheduled) {
      alert("Please schedule a draft first.");
      return;
    }
    try {
      // Generate draft order
      const order = generateDraftOrder();
      setDraftOrder(order);
      
      // Save draft order to database for other users
      // First delete any existing draft order
      await supabase
        .from("league_draft_order")
        .delete()
        .eq("league_id", league_id);
      
      // Then insert the new draft order
      const draftOrderToSave = order.map((pick, index) => ({
        league_id: Number(league_id),
        team_id: pick.teamId,
        team_name: pick.teamName,
        user_id: pick.userId,
        round: pick.round,
        pick_number: index,
      }));
      
      const { error: orderError } = await supabase
        .from("league_draft_order")
        .insert(draftOrderToSave);
        
      if (orderError) {
        console.error("Error saving draft order:", orderError);
        // Continue anyway, as we have the order locally
      }
      
      // Update draft status
      const { error } = await supabase
        .from("league_drafts")
        .update({ status: "In Progress" })
        .eq("league_id", league_id);
        
      if (error) throw error;
      
      // Set up local draft state
      setCurrentTeamIndex(0);
      setCurrentRound(1);
      setDraftInProgress(true);
      setDraftedPlayers([]);
      await fetchAvailablePlayers();
      setTimeLeft(60);
      setTimerActive(true);
    } catch (error) {
      console.error("Error starting draft:", error.message);
      alert("Failed to start draft. Please try again.");
    }
  };

  // GENERATE DRAFT ORDER (Snake Draft)
  const generateDraftOrder = useCallback(() => {
    const order = [];
    const numTeams = allTeams.length;
    console.log("Generating draft order with teams:", allTeams);
    if (numTeams === 0) {
      alert("No teams available for draft. Please ensure all participants have created their teams.");
      return [];
    }
    for (let round = 1; round <= totalRounds; round++) {
      const roundOrder = round % 2 === 0 ? [...allTeams].reverse() : [...allTeams];
      order.push(
        ...roundOrder.map((team, idx) => {
          console.log(`Team ${idx} in round ${round}:`, team);
          return {
            round,
            teamId: team.id,
            teamName: team.team_name || "No Team Name",
            userId: team.user_id,
            overall: (round - 1) * numTeams + (round % 2 === 0 ? numTeams - idx : idx + 1),
          };
        })
      );
    }
    const sortedOrder = order.sort((a, b) => a.overall - b.overall);
    console.log("Final draft order:", sortedOrder);
    return sortedOrder;
  }, [allTeams, totalRounds]);

  // FETCH DRAFT ORDER FROM SERVER
  const fetchDraftOrder = useCallback(async () => {
    try {
      // First check if draft order is stored in the database
      const { data: draftOrderData, error } = await supabase
        .from("league_draft_order")
        .select("*")
        .eq("league_id", league_id)
        .order("pick_number", { ascending: true });
      
      if (error) {
        console.error("Error fetching draft order:", error);
        return;
      }
      
      if (draftOrderData && draftOrderData.length > 0) {
        // Convert the database format to the format used in the component
        const formattedOrder = draftOrderData.map(pick => ({
          teamId: pick.team_id,
          teamName: pick.team_name,
          userId: pick.user_id,
          round: pick.round,
          overall: pick.pick_number
        }));
        
        console.log("Fetched draft order from server:", formattedOrder);
        setDraftOrder(formattedOrder);
        return formattedOrder;
      } else {
        // If no draft order in database, generate it locally
        console.log("No draft order found in database, generating locally");
        const order = generateDraftOrder();
        setDraftOrder(order);
        return order;
      }
    } catch (error) {
      console.error("Error in fetchDraftOrder:", error);
      // Fallback to local generation
      const order = generateDraftOrder();
      setDraftOrder(order);
      return order;
    }
  }, [league_id, supabase, generateDraftOrder]);

  // Fetch available players (not yet drafted)
  const fetchAvailablePlayers = useCallback(async () => {
    try {
      // First get all drafted players for this league
      const { data: draftedIds, error: draftedError } = await supabase
        .from("league_players")
        .select("player_id")
        .eq("league_id", league_id);
      
      if (draftedError) {
        console.error("Error fetching drafted players:", draftedError);
        return;
      }
      
      // Create an array of already drafted player IDs
      const draftedPlayerIds = draftedIds ? draftedIds.map((d) => d.player_id) : [];
      console.log(`Found ${draftedPlayerIds.length} already drafted players`);
      
      // Fetch all available players
      let query = supabase
        .from("nfl_players")
        .select("player_id, name, position, team_id");
      
      // Filter out already drafted players if there are any
      if (draftedPlayerIds.length > 0) {
        query = query.not("player_id", "in", `(${draftedPlayerIds.join(",")})`);
      }
      
      const { data, error } = await query;
      
      if (error) {
        console.error("Error fetching available players:", error);
        throw error;
      }
      
      console.log(`Fetched ${data.length} available players`);
      setAvailablePlayers(data);
    } catch (error) {
      console.error("Error fetching players for draft:", error.message);
    }
  }, [league_id, supabase]);

  // AUTO-PICK: useCallback to ensure stability
  const autoPickPlayer = useCallback(() => {
    if (!draftInProgress || draftComplete || isPicking) return;
    if (availablePlayers.length > 0) {
      console.log("Auto-picking player...");
      const bestPlayer = availablePlayers[0];
      console.log("Auto-picking player:", bestPlayer.name);
      draftPlayer(bestPlayer.player_id);
    } else {
      console.log("No available players for auto-pick");
      fetchAvailablePlayers().then(() => {
        if (availablePlayers.length === 0) {
          console.log("Still no available players after refresh");
          if (currentTeamIndex + 1 >= draftOrder.length) {
            completeDraft();
          } else {
            setCurrentTeamIndex(currentTeamIndex + 1);
            setTimeLeft(60);
          }
        } else {
          const bestPlayer = availablePlayers[0];
          draftPlayer(bestPlayer.player_id);
        }
      });
    }
  }, [
    draftInProgress,
    draftComplete,
    availablePlayers,
    currentTeamIndex,
    draftOrder,
    draftPlayer,
    completeDraft,
    isPicking,
    fetchAvailablePlayers,
  ]);

  // TIMER EFFECT: counts down and auto-picks when time expires
  useEffect(() => {
    let timerId;
    if (draftInProgress && !draftComplete && timerActive) {
      timerId = setInterval(() => {
        setTimeLeft((prev) => {
          if (prev <= 1) {
            autoPickPlayer();
            return 60;
          }
          return prev - 1;
        });
      }, 1000);
    }
    return () => clearInterval(timerId);
  }, [draftInProgress, draftComplete, timerActive, autoPickPlayer]);

  // REALTIME SUBSCRIPTION: Listen for new drafted players
  useEffect(() => {
    if (!league_id || !supabase) return;
    
    // Create a channel for real-time updates
    const draftedChannel = supabase
      .channel(`public:league_players:league_id=eq.${league_id}`)
      .on(
        "postgres_changes",
        {
          event: "INSERT",
          schema: "public",
          table: "league_players",
          filter: `league_id=eq.${league_id}`,
        },
        async (payload) => {
          console.log("Realtime: New drafted player", payload.new);
          
          // Only process if we didn't make this change ourselves
          if (!isPicking) {
            try {
              // Get player details
              const { data: playerData, error: playerError } = await supabase
                .from("nfl_players")
                .select("*")
                .eq("player_id", payload.new.player_id)
                .single();
                
              if (playerError) throw playerError;
              
              // Get team details
              const { data: teamData, error: teamError } = await supabase
                .from("league_teams")
                .select("*")
                .eq("id", payload.new.team_id)
                .single();
                
              if (teamError) throw teamError;
              
              // Add to drafted players
              setDraftedPlayers((prev) => [
                ...prev,
                {
                  player: playerData,
                  team: teamData.team_name,
                  round: payload.new.draft_round,
                  pick: payload.new.draft_position,
                },
              ]);
              
              // Remove from available players
              setAvailablePlayers((prev) =>
                prev.filter((p) => p.player_id !== payload.new.player_id)
              );
              
              // Move to next pick
              if (currentTeamIndex + 1 >= draftOrder.length) {
                setDraftInProgress(false);
                setDraftComplete(true);
                setTimerActive(false);
              } else {
                setCurrentTeamIndex((prev) => prev + 1);
                setTimeLeft(60);
              }
            } catch (error) {
              console.error("Error processing real-time draft update:", error);
            }
          }
        }
      )
      .subscribe();
      
    // Also listen for draft status changes
    const draftStatusChannel = supabase
      .channel(`public:league_drafts:league_id=eq.${league_id}`)
      .on(
        "postgres_changes",
        {
          event: "UPDATE",
          schema: "public",
          table: "league_drafts",
          filter: `league_id=eq.${league_id}`,
        },
        async (payload) => {
          console.log("Realtime: Draft status changed", payload.new);
          
          // Update draft status based on payload
          if (payload.new.status === "In Progress" && !draftInProgress) {
            console.log("Draft started remotely");
            // Fetch draft order and available players
            await fetchDraftOrder();
            setDraftInProgress(true);
            setDraftComplete(false);
            setCurrentTeamIndex(0);
            setCurrentRound(1);
            await fetchAvailablePlayers();
            setTimeLeft(60);
            setTimerActive(true);
          } else if (payload.new.status === "Completed" && !draftComplete) {
            console.log("Draft completed remotely");
            setDraftInProgress(false);
            setDraftComplete(true);
            setTimerActive(false);
          } else if (payload.new.status === "Scheduled" && !draftScheduled) {
            console.log("Draft scheduled remotely");
            setDraftScheduled(true);
            setDraftInProgress(false);
            setDraftComplete(false);
          }
        }
      )
      .subscribe();

    // Cleanup function
    return () => {
      supabase.removeChannel(draftedChannel);
      supabase.removeChannel(draftStatusChannel);
    };
  }, [
    league_id, 
    supabase, 
    isPicking, 
    currentTeamIndex, 
    draftOrder, 
    draftInProgress, 
    draftComplete, 
    draftScheduled,
    fetchAvailablePlayers,
    fetchDraftOrder
  ]);

  return (
    <DraftContainer>
      <h2>Draft Central</h2>
      {!userHasTeam ? (
        <div
          style={{
            backgroundColor: "#2e2e3d",
            borderRadius: "8px",
            padding: "20px",
            marginBottom: "20px",
            textAlign: "center",
          }}
        >
          <h3 style={{ color: "#dc3545", marginBottom: "15px" }}>
            You Need to Create a Team First
          </h3>
          <p>
            Before you can participate in the draft, you need to create your team
            in the Teams section.
          </p>
          <p>
            Go to the Teams tab and create your team, then come back to join the
            draft.
          </p>
        </div>
      ) : !draftScheduled ? (
        <div className="draft-controls">
          <input
            type="date"
            value={draftDate}
            onChange={(e) => setDraftDate(e.target.value)}
            placeholder="Select date"
          />
          <input
            type="time"
            value={draftTime}
            onChange={(e) => setDraftTime(e.target.value)}
            placeholder="Select time"
          />
          <button onClick={scheduleDraft}>Schedule Draft</button>
        </div>
      ) : draftComplete ? (
        <div className="draft-summary">
          <h3>Draft Complete</h3>
          <div className="drafted-players">
            <h4>Draft Results (Completed Round {currentRound})</h4>
            <table>
              <thead>
                <tr>
                  <th>Round</th>
                  <th>Pick</th>
                  <th>Team</th>
                  <th>Player</th>
                  <th>Position</th>
                </tr>
              </thead>
              <tbody>
                {draftedPlayers.map((pick, index) => (
                  <tr key={index}>
                    <td>{Math.floor(index / leagueMembers.length) + 1}</td>
                    <td>{index + 1}</td>
                    <td>{pick.team}</td>
                    <td>{pick.player.name || pick.player.player_id}</td>
                    <td>{pick.player.position || "N/A"}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      ) : !draftInProgress ? (
        <div className="draft-controls">
          <p>Draft scheduled for: {new Date(draftTime).toLocaleString()}</p>
          <button onClick={startDraft}>Start Draft Now</button>
        </div>
      ) : (
        <div className="draft-board">
          <div className="draft-status">
            <h3>Round {currentRound}</h3>
            <p>
              Current Team Picking:{" "}
              {draftOrder[currentTeamIndex]?.teamName || "Unknown"}
            </p>
            <p>
              Time Left: <strong>{timeLeft}</strong> sec
            </p>
          </div>
          <div className="draft-columns">
            <div className="available-players">
              <h3>Available Players</h3>
              <input
                type="text"
                placeholder="Search players..."
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
              />
              <ul>
                {availablePlayers
                  .filter((player) =>
                    player.name.toLowerCase().includes(searchQuery.toLowerCase())
                  )
                  .map((player) => (
                    <li
                      key={player.player_id}
                      onClick={() => draftPlayer(player.player_id)}
                    >
                      {player.name} - {player.position} -{" "}
                      {player.team_id ?? "No TeamID"}
                    </li>
                  ))}
              </ul>
            </div>
            <div className="drafted-players">
              <h3>Drafted Players</h3>
              <ul>
                {draftedPlayers.map((item, index) => (
                  <li key={index}>
                    Round {item.round}, Pick {item.pick}: {item.team} drafted{" "}
                    {item.player.name || item.player.player_id}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      )}
    </DraftContainer>
  );
};

export default Draft;
