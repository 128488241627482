import React, { useEffect, useState } from "react";
import Navbar from "./Navbar"; // Import the reusable Navbar component
import styled from "styled-components";

// Styled components
const NFLPage = styled.div`
  padding-top: 60px; /* Ensure content starts below the fixed navbar */
`;

const MainContent = styled.div`
  padding: 20px;
`;

const SectionTitle = styled.h2`
  color: #007bff;
  margin-bottom: 20px;
`;

const CardContainer = styled.div`
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
  
  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
`;

const Card = styled.div`
  background-color: #2a2a2a;
  padding: 15px;
  border-radius: 8px;
  width: 300px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
`;

const HighlightVideo = styled.video`
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  width: 100%;
`;

const StandingsTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
  background-color: #1a1a1a;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  
  th, td {
    padding: 10px;
    text-align: left;
    color: white;
    border-bottom: 1px solid #333;
    
    @media (max-width: 768px) {
      font-size: 14px;
    }
  }
  
  th {
    background-color: #007bff;
    color: white;
    font-weight: bold;
  }
  
  tr:last-child td {
    border-bottom: none;
  }
  
  tr:nth-child(odd) {
    background-color: #2a2a2a;
  }
  
  tr:nth-child(even) {
    background-color: #1a1a1a;
  }
`;

const ScheduleList = styled.ul`
  list-style: none;
  padding: 0;
`;

const ScheduleItem = styled.li`
  background-color: #2a2a2a;
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  color: white;
  
  @media (max-width: 768px) {
    font-size: 14px;
  }
`;

const Section = styled.section`
  margin-bottom: 30px;
`;

const NewsLink = styled.a`
  color: #007bff;
  text-decoration: none;
  
  &:hover {
    text-decoration: underline;
  }
`;

const NFL = () => {
  const [liveScores, setLiveScores] = useState([]);
  const [newsArticles, setNewsArticles] = useState([]);
  const [standings, setStandings] = useState([]);
  const [schedule, setSchedule] = useState([]);
  const [loading, setLoading] = useState(true);

  // Fetch data for live scores, news, standings, and schedule
  useEffect(() => {
    const fetchNFLData = async () => {
      try {
        // Fetch live scores
        const scoresResponse = await fetch("/api/nfl/scores");
        const scoresData = await scoresResponse.json();
        setLiveScores(scoresData);

        // Fetch news articles
        const newsResponse = await fetch("/api/nfl/news");
        const newsData = await newsResponse.json();
        setNewsArticles(newsData.articles);

        // Fetch standings
        const standingsResponse = await fetch("/api/nfl/standings");
        const standingsData = await standingsResponse.json();
        setStandings(standingsData);

        // Fetch schedule
        const scheduleResponse = await fetch("/api/nfl/schedule");
        const scheduleData = await scheduleResponse.json();
        setSchedule(scheduleData);
      } catch (error) {
        console.error("Error fetching NFL data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchNFLData();
  }, []);

  return (
    <NFLPage>
      {/* Navbar Section */}
      <Navbar /> {/* Reusable Navbar */}

      <MainContent>
        {/* Live Scores Section */}
        <Section>
          <SectionTitle>Live Scores</SectionTitle>
          {loading ? (
            <p>Loading live scores...</p>
          ) : (
            <CardContainer>
              {liveScores.map((game, index) => (
                <Card key={index}>
                  <h3>
                    {game.team1} vs. {game.team2}
                  </h3>
                  <p>{game.score}</p>
                </Card>
              ))}
            </CardContainer>
          )}
        </Section>

        {/* Highlights Section */}
        <Section>
          <SectionTitle>Highlights</SectionTitle>
          <HighlightVideo
            controls
            src="https://sample-videos.com/video123/mp4/720/big_buck_bunny_720p_1mb.mp4"
          >
            Your browser does not support the video tag.
          </HighlightVideo>
        </Section>

        {/* News Section */}
        <Section>
          <SectionTitle>Recent News</SectionTitle>
          {loading ? (
            <p>Loading news...</p>
          ) : (
            <CardContainer>
              {newsArticles.map((article, index) => (
                <Card key={index}>
                  <h3>{article.title}</h3>
                  <p>{article.description}</p>
                  <NewsLink href={article.url} target="_blank" rel="noopener noreferrer">
                    Read More
                  </NewsLink>
                </Card>
              ))}
            </CardContainer>
          )}
        </Section>

        {/* Standings Section */}
        <Section>
          <SectionTitle>Standings</SectionTitle>
          {loading ? (
            <p>Loading standings...</p>
          ) : (
            <StandingsTable>
              <thead>
                <tr>
                  <th>Team</th>
                  <th>Wins</th>
                  <th>Losses</th>
                  <th>Rank</th>
                </tr>
              </thead>
              <tbody>
                {standings.map((team, index) => (
                  <tr key={index}>
                    <td>{team.name}</td>
                    <td>{team.wins}</td>
                    <td>{team.losses}</td>
                    <td>{team.rank}</td>
                  </tr>
                ))}
              </tbody>
            </StandingsTable>
          )}
        </Section>

        {/* Schedule Section */}
        <Section>
          <SectionTitle>Upcoming Games</SectionTitle>
          {loading ? (
            <p>Loading schedule...</p>
          ) : (
            <ScheduleList>
              {schedule.map((game, index) => (
                <ScheduleItem key={index}>
                  {game.date}: {game.team1} vs. {game.team2}
                </ScheduleItem>
              ))}
            </ScheduleList>
          )}
        </Section>
      </MainContent>
    </NFLPage>
  );
};

export default NFL;
