import React, { useState, useEffect } from "react";
import styled from "styled-components";

const TeamRosterContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  
  .roster-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    h3 {
      color: #007bff;
    }
    select {
      padding: 8px;
      background-color: #2e2e3d;
      color: white;
      border: none;
      border-radius: 5px;
    }
  }
  
  .roster-table {
    background-color: #2e2e3d;
    border-radius: 8px;
    padding: 15px;
    
    table {
      width: 100%;
      border-collapse: collapse;
      th,
      td {
        padding: 12px;
        text-align: left;
        border-bottom: 1px solid #444;
      }
      th {
        color: #007bff;
        font-weight: bold;
      }
      tr:nth-child(even) {
        background-color: #2a2a3a;
      }
      tr:hover {
        background-color: #3a3a4a;
      }
      .starter {
        color: #28a745;
      }
      .bench {
        color: #aaa;
      }
      .action-cell {
        display: flex;
        gap: 5px;
        button {
          padding: 5px 10px;
          background-color: #007bff;
          color: white;
          border: none;
          border-radius: 5px;
          cursor: pointer;
          font-size: 12px;
          &:hover {
            background-color: #0056b3;
          }
          &.bench-button {
            background-color: #6c757d;
            &:hover {
              background-color: #5a6268;
            }
          }
        }
      }
    }
  }
`;

const TeamCreationForm = styled.div`
  background-color: #2e2e3d;
  border-radius: 8px;
  padding: 20px;
  margin-bottom: 20px;
  
  h3 {
    color: #007bff;
    margin-bottom: 15px;
  }
  
  .form-group {
    margin-bottom: 15px;
    
    label {
      display: block;
      margin-bottom: 5px;
      color: #ddd;
    }
    
    input {
      width: 100%;
      padding: 10px;
      background-color: #1e1e2d;
      border: 1px solid #444;
      border-radius: 5px;
      color: white;
      
      &:focus {
        outline: none;
        border-color: #007bff;
      }
    }
  }
  
  button {
    padding: 10px 15px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    
    &:hover {
      background-color: #0056b3;
    }
    
    &:disabled {
      background-color: #6c757d;
      cursor: not-allowed;
    }
  }
  
  .error-message {
    color: #dc3545;
    margin-top: 10px;
  }
  
  .success-message {
    color: #28a745;
    margin-top: 10px;
  }
`;

const Teams = ({ supabase, league_id, user, leagueMembers }) => {
  const [selectedTeam, setSelectedTeam] = useState("");
  const [teamRoster, setTeamRoster] = useState([]);
  const [userTeam, setUserTeam] = useState(null);
  const [teamName, setTeamName] = useState("");
  const [isCreatingTeam, setIsCreatingTeam] = useState(false);
  const [isEditingTeam, setIsEditingTeam] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [allTeams, setAllTeams] = useState([]);

  // Fetch all teams in the league
  useEffect(() => {
    if (!league_id) return;
    
    const fetchTeams = async () => {
      const { data, error } = await supabase
        .from("league_teams")
        .select("*")
        .eq("league_id", league_id);
        
      if (!error && data) {
        setAllTeams(data);
        
        // Check if the current user has a team
        const userTeamData = data.find(team => team.user_id === user.id);
        if (userTeamData) {
          setUserTeam(userTeamData);
          setTeamName(userTeamData.team_name);
        } else {
          setIsCreatingTeam(true);
        }
      }
    };
    
    fetchTeams();
  }, [league_id, supabase, user.id]);

  // Fetch team roster when a team is selected
  useEffect(() => {
    if (!league_id || !selectedTeam) return;
    const fetchTeamRoster = async () => {
      const { data, error } = await supabase
        .from("league_players")
        .select("*, nfl_players(*)")
        .eq("league_id", league_id)
        .eq("team_id", selectedTeam);
      if (!error) setTeamRoster(data || []);
    };
    fetchTeamRoster();
  }, [league_id, selectedTeam, supabase]);

  const toggleStarterStatus = async (playerId, isCurrentlyStarter) => {
    const { error } = await supabase
      .from("league_players")
      .update({ is_starter: !isCurrentlyStarter })
      .eq("id", playerId);
    if (!error) {
      setTeamRoster((prev) =>
        prev.map((player) =>
          player.id === playerId
            ? { ...player, is_starter: !isCurrentlyStarter }
            : player
        )
      );
    }
  };

  const handleCreateTeam = async () => {
    if (!teamName.trim()) {
      setErrorMessage("Team name cannot be empty");
      return;
    }
    
    setErrorMessage("");
    
    try {
      const { data, error } = await supabase
        .from("league_teams")
        .insert([
          {
            league_id: league_id,
            user_id: user.id,
            team_name: teamName,
            wins: 0,
            losses: 0,
            ties: 0,
            points_for: 0,
            points_against: 0
          }
        ])
        .select();
        
      if (error) throw error;
      
      setUserTeam(data[0]);
      setIsCreatingTeam(false);
      setSuccessMessage("Team created successfully!");
      
      // Update all teams list
      setAllTeams(prev => [...prev, data[0]]);
      
      // Clear success message after 3 seconds
      setTimeout(() => setSuccessMessage(""), 3000);
    } catch (error) {
      setErrorMessage(`Failed to create team: ${error.message}`);
    }
  };

  const handleUpdateTeam = async () => {
    if (!teamName.trim()) {
      setErrorMessage("Team name cannot be empty");
      return;
    }
    
    setErrorMessage("");
    
    try {
      const { error } = await supabase
        .from("league_teams")
        .update({ team_name: teamName })
        .eq("id", userTeam.id);
        
      if (error) throw error;
      
      setUserTeam(prev => ({ ...prev, team_name: teamName }));
      setIsEditingTeam(false);
      setSuccessMessage("Team updated successfully!");
      
      // Update all teams list
      setAllTeams(prev => 
        prev.map(team => 
          team.id === userTeam.id 
            ? { ...team, team_name: teamName } 
            : team
        )
      );
      
      // Clear success message after 3 seconds
      setTimeout(() => setSuccessMessage(""), 3000);
    } catch (error) {
      setErrorMessage(`Failed to update team: ${error.message}`);
    }
  };

  return (
    <div>
      <h2>Teams</h2>
      
      {/* Team Creation/Editing Form */}
      {(isCreatingTeam || isEditingTeam) && (
        <TeamCreationForm>
          <h3>{isCreatingTeam ? "Create Your Team" : "Edit Your Team"}</h3>
          <div className="form-group">
            <label htmlFor="teamName">Team Name</label>
            <input
              type="text"
              id="teamName"
              value={teamName}
              onChange={(e) => setTeamName(e.target.value)}
              placeholder="Enter your team name"
            />
          </div>
          
          {errorMessage && <p className="error-message">{errorMessage}</p>}
          {successMessage && <p className="success-message">{successMessage}</p>}
          
          <button 
            onClick={isCreatingTeam ? handleCreateTeam : handleUpdateTeam}
          >
            {isCreatingTeam ? "Create Team" : "Update Team"}
          </button>
          
          {isEditingTeam && (
            <button 
              style={{ marginLeft: '10px', backgroundColor: '#6c757d' }}
              onClick={() => {
                setIsEditingTeam(false);
                setTeamName(userTeam.team_name);
                setErrorMessage("");
              }}
            >
              Cancel
            </button>
          )}
        </TeamCreationForm>
      )}
      
      {/* User's Team Display */}
      {userTeam && !isEditingTeam && (
        <div style={{ marginBottom: '20px' }}>
          <h3>Your Team: {userTeam.team_name}</h3>
          <button 
            style={{ 
              padding: '8px 15px',
              backgroundColor: '#007bff',
              color: 'white',
              border: 'none',
              borderRadius: '5px',
              cursor: 'pointer',
              marginTop: '10px'
            }}
            onClick={() => setIsEditingTeam(true)}
          >
            Edit Team
          </button>
        </div>
      )}
      
      {/* Team Roster Viewer */}
      <TeamRosterContainer>
        <div className="roster-header">
          <h3>View Team Roster:</h3>
          <select
            value={selectedTeam}
            onChange={(e) => setSelectedTeam(e.target.value)}
          >
            <option value="">Select a team</option>
            {allTeams.map((team) => (
              <option key={team.id} value={team.id}>
                {team.team_name}
              </option>
            ))}
          </select>
        </div>

        {selectedTeam ? (
          <div className="roster-table">
            <table>
              <thead>
                <tr>
                  <th>Player</th>
                  <th>Position</th>
                  <th>Team</th>
                  <th>Status</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {teamRoster.length > 0 ? (
                  teamRoster.map((player) => (
                    <tr key={player.id}>
                      <td>{player.nfl_players?.name || "Unknown Player"}</td>
                      <td>{player.nfl_players?.position || "N/A"}</td>
                      <td>{player.nfl_players?.team || "N/A"}</td>
                      <td className={player.is_starter ? "starter" : "bench"}>
                        {player.is_starter ? "Starter" : "Bench"}
                      </td>
                      <td className="action-cell">
                        {/* Only show action buttons if it's the user's team */}
                        {userTeam && userTeam.id === parseInt(selectedTeam) && (
                          <button
                            onClick={() =>
                              toggleStarterStatus(player.id, player.is_starter)
                            }
                            className={player.is_starter ? "bench-button" : ""}
                          >
                            {player.is_starter ? "Bench" : "Start"}
                          </button>
                        )}
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="5">No players on roster</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        ) : (
          <p>Select a team to view roster</p>
        )}
      </TeamRosterContainer>
    </div>
  );
};

export default Teams;
