import React from "react";
import styled from "styled-components";

const ActivityList = styled.ul`
  list-style: none;
  padding: 0;
  li {
    color: #ccc;
    margin-bottom: 10px;
    padding-left: 10px;
    border-left: 3px solid #007bff;
  }
`;

const Overview = ({ leagueName }) => {
  return (
    <div>
      <h2>League Overview</h2>
      <p>
        Welcome to {leagueName}! Here you can manage your fantasy league, view
        teams, and track standings.
      </p>
      <h2>Recent Activity</h2>
      <ActivityList>
        {/* Placeholder – insert recent activity items here */}
        <li>No recent activity.</li>
      </ActivityList>
    </div>
  );
};

export default Overview;
