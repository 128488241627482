import React, { useState, useEffect } from "react";
import styled from "styled-components";

const StandingsList = styled.div`
  background-color: #2e2e3d;
  border-radius: 8px;
  padding: 15px;
  table {
    width: 100%;
    border-collapse: collapse;
    th,
    td {
      padding: 12px;
      text-align: left;
      border-bottom: 1px solid #444;
    }
    th {
      color: #007bff;
      font-weight: bold;
    }
    tr:nth-child(even) {
      background-color: #2a2a3a;
    }
    tr:hover {
      background-color: #3a3a4a;
    }
  }
`;

const Standings = ({ supabase, league_id }) => {
  const [standings, setStandings] = useState([]);

  useEffect(() => {
    if (!league_id) return;
    const fetchStandings = async () => {
      const { data, error } = await supabase
        .from("league_standings")
        .select(
          "*, team:team_id(id, team_name, wins, losses, ties, points_for, points_against)"
        )
        .eq("league_id", league_id)
        .order("rank", { ascending: true });
      if (!error) setStandings(data || []);
    };
    fetchStandings();
  }, [supabase, league_id]);

  return (
    <div>
      <h2>League Standings</h2>
      <StandingsList>
        <table>
          <thead>
            <tr>
              <th>Rank</th>
              <th>Team</th>
              <th>Record</th>
              <th>PF</th>
              <th>PA</th>
            </tr>
          </thead>
          <tbody>
            {standings.map((s) => (
              <tr key={s.id}>
                <td>{s.rank}</td>
                <td>{s.team?.team_name || "Unknown Team"}</td>
                <td>
                  {s.team?.wins || 0}-{s.team?.losses || 0}
                  {s.team?.ties > 0 ? `-${s.team.ties}` : ""}
                </td>
                <td>{s.team?.points_for || 0}</td>
                <td>{s.team?.points_against || 0}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </StandingsList>
    </div>
  );
};

export default Standings;
