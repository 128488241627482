import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Navbar from "./Navbar"; // Import reusable Navbar component
import styled from "styled-components";

// Styled components
const HomepageContainer = styled.div`
  margin: 0;
  padding: 0;
  font-family: "Roboto", sans-serif;
  background-color: #1a1a1a;
  color: white;
  overflow-x: hidden;
  padding-top: 59px; /* Space for fixed navbar */
`;

const HeroSection = styled.div`
  position: relative;
  width: 100%;
  height: 70vh;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const BackgroundVideo = styled.video`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 1;
  pointer-events: none;
`;

const HeroOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 2;
`;

const HeroContent = styled.div`
  text-align: center;
  color: white;
  z-index: 3;
  position: relative;
  
  h1 {
    font-size: 3rem;
    margin-bottom: 1rem;
    text-shadow: 2px 2px 6px rgba(0, 0, 0, 0.7);
    
    @media (max-width: 768px) {
      font-size: 2.5rem;
    }
    
    @media (max-width: 480px) {
      font-size: 2rem;
    }
  }
  
  p {
    font-size: 1.2rem;
    margin-bottom: 1.5rem;
    color: #ddd;
    text-shadow: 1px 1px 4px rgba(0, 0, 0, 0.7);
    
    @media (max-width: 768px) {
      font-size: 1rem;
    }
    
    @media (max-width: 480px) {
      font-size: 0.9rem;
    }
  }
`;

const Button = styled.button`
  padding: 12px 24px;
  font-size: 1rem;
  border: none;
  border-radius: 5px;
  background-color: #0056b3;
  color: white;
  cursor: pointer;
  transition: background-color 0.3s ease;
  
  &:hover {
    background-color: #004a99;
  }
`;

const NewsSection = styled.div`
  padding: 40px 20px;
  background-color: #1a1a1a;
  color: white;
  text-align: center;
  
  h2 {
    margin-bottom: 20px;
    font-size: 24px;
    color: #ccc;
  }
`;

const NewsCards = styled.div`
  display: flex;
  gap: 20px;
  justify-content: center;
  flex-wrap: wrap;
`;

const NewsCard = styled.div`
  background-color: #2a2a2a;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  overflow: hidden;
  width: 300px;
  display: flex;
  flex-direction: column;
`;

const NewsImage = styled.img`
  width: 100%;
  height: 180px;
  object-fit: cover;
`;

const NewsContent = styled.div`
  padding: 15px;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const NewsTitle = styled.h3`
  font-size: 18px;
  font-weight: bold;
  color: #fff;
`;

const NewsDescription = styled.p`
  font-size: 14px;
  color: #ccc;
  line-height: 1.4;
  flex-grow: 1;
`;

const NewsLink = styled.a`
  color: #007bff;
  font-size: 14px;
  text-decoration: none;
  align-self: flex-start;
  transition: color 0.3s ease;
  
  &:hover {
    color: #0056b3;
  }
`;

const ErrorText = styled.p`
  color: red;
`;

const Homepage = () => {
  const navigate = useNavigate();

  const [articles, setArticles] = useState([]);
  const [loading, setLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    const fetchNews = async () => {
      try {
        const response = await fetch("/api/fetchNews");
        if (!response.ok) {
          throw new Error("Failed to fetch news articles");
        }
        const data = await response.json();
        setArticles(data.articles); // Set fetched articles
      } catch (error) {
        console.error("Error fetching news:", error);
        setErrorMessage("Failed to load news articles."); // Set error message
      } finally {
        setLoading(false); // Set loading state to false
      }
    };

    fetchNews();
  }, []);

  return (
    <HomepageContainer>
      {/* Navbar Section */}
      <Navbar />

      {/* Hero Section */}
      <HeroSection>
        <div className="video-overlay">
          <BackgroundVideo autoPlay loop muted playsInline>
            <source
              src="https://videos.pexels.com/video-files/5182640/5182640-uhd_2560_1440_25fps.mp4"
              type="video/mp4"
            />
            Your browser does not support the video tag.
          </BackgroundVideo>
          <HeroOverlay></HeroOverlay>
          <HeroContent>
            <h1>Welcome to MilkySports</h1>
            <p>Your go-to platform for fantasy sports!</p>
            <Button onClick={() => navigate("/fantasy")}>
              Get Started
            </Button>
          </HeroContent>
        </div>
      </HeroSection>

      {/* News Section */}
      <NewsSection>
        <h2>Recent News</h2>
        {loading ? (
          <p>Loading sports news...</p>
        ) : errorMessage ? (
          <ErrorText>{errorMessage}</ErrorText>
        ) : (
          <NewsCards>
            {articles.slice(0, 5).map((article, index) => (
              <NewsCard key={index}>
                {article.image && (
                  <NewsImage
                    src={article.image}
                    alt={article.title}
                  />
                )}
                <NewsContent>
                  <NewsTitle>{article.title}</NewsTitle>
                  <NewsDescription>{article.description}</NewsDescription>
                  <NewsLink
                    href={article.url}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Read more
                  </NewsLink>
                </NewsContent>
              </NewsCard>
            ))}
          </NewsCards>
        )}
      </NewsSection>
    </HomepageContainer>
  );
};

export default Homepage;
