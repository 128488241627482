import React, { useState, useEffect } from "react";
import styled from "styled-components";

const WaiverWireContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  
  .waiver-controls {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    .search-filter {
      display: flex;
      gap: 10px;
      input,
      select {
        padding: 8px;
        background-color: #2e2e3d;
        color: white;
        border: none;
        border-radius: 5px;
      }
    }
  }
  
  .players-table {
    background-color: #2e2e3d;
    border-radius: 8px;
    padding: 15px;
    table {
      width: 100%;
      border-collapse: collapse;
      th,
      td {
        padding: 12px;
        text-align: left;
        border-bottom: 1px solid #444;
      }
      th {
        color: #007bff;
        font-weight: bold;
      }
      tr:nth-child(even) {
        background-color: #2a2a3a;
      }
      tr:hover {
        background-color: #3a3a4a;
      }
      .action-cell {
        display: flex;
        gap: 5px;
        button {
          padding: 5px 10px;
          background-color: #28a745;
          color: white;
          border: none;
          border-radius: 5px;
          cursor: pointer;
          font-size: 12px;
          &:hover {
            background-color: #218838;
          }
          &:disabled {
            background-color: #6c757d;
            cursor: not-allowed;
          }
        }
      }
    }
  }
  
  .pagination {
    display: flex;
    justify-content: center;
    gap: 10px;
    margin-top: 20px;
    button {
      padding: 5px 10px;
      background-color: #2e2e3d;
      color: white;
      border: none;
      border-radius: 5px;
      cursor: pointer;
      &:hover {
        background-color: #3a3a4a;
      }
      &:disabled {
        opacity: 0.5;
        cursor: not-allowed;
      }
      &.active {
        background-color: #007bff;
      }
    }
  }
`;

const WaiverWire = ({ supabase, league_id }) => {
  const [freeAgents, setFreeAgents] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [positionFilter, setPositionFilter] = useState("All");
  const [currentPage, setCurrentPage] = useState(1);
  const playersPerPage = 10;

  useEffect(() => {
    if (!league_id) return;
    const fetchFreeAgents = async () => {
      try {
        const { data: leaguePlayers, error: leaguePlayersError } =
          await supabase
            .from("league_players")
            .select("player_id")
            .eq("league_id", Number(league_id));
        if (leaguePlayersError) throw leaguePlayersError;
        const playerIds = leaguePlayers.map((p) => p.player_id);
        const { data: availablePlayers, error: availablePlayersError } =
          await supabase
            .from("players")
            .select("*")
            .eq("sport_id", 1) // Adjust this as needed
            .not(
              "player_id",
              "in",
              playerIds.length > 0 ? `(${playerIds.join(",")})` : "(0)"
            );
        if (availablePlayersError) throw availablePlayersError;
        setFreeAgents(availablePlayers);
      } catch (error) {
        console.error("Error fetching free agents:", error.message);
      }
    };
    fetchFreeAgents();
  }, [supabase, league_id]);

  const indexOfLastPlayer = currentPage * playersPerPage;
  const indexOfFirstPlayer = indexOfLastPlayer - playersPerPage;
  const currentPlayers = freeAgents.slice(indexOfFirstPlayer, indexOfLastPlayer);
  const totalPages = Math.ceil(freeAgents.length / playersPerPage);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <div>
      <h2>Waiver Wire</h2>
      <WaiverWireContainer>
        <div className="waiver-controls">
          <div className="search-filter">
            <input
              type="text"
              placeholder="Search players..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
            <select
              value={positionFilter}
              onChange={(e) => setPositionFilter(e.target.value)}
            >
              <option value="All">All Positions</option>
              <option value="QB">QB</option>
              <option value="RB">RB</option>
              <option value="WR">WR</option>
              <option value="TE">TE</option>
              <option value="K">K</option>
              <option value="DEF">DEF</option>
            </select>
          </div>
        </div>
        <div className="players-table">
          <table>
            <thead>
              <tr>
                <th>Player</th>
                <th>Position</th>
                <th>Team</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {currentPlayers.length > 0 ? (
                currentPlayers.map((p) => (
                  <tr key={p.id}>
                    <td>{p.name}</td>
                    <td>{p.position}</td>
                    <td>{p.team}</td>
                    <td className="action-cell">
                      <button onClick={() => alert("Add Player clicked")}>
                        Add Player
                      </button>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="4">No free agents available</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        {totalPages > 1 && (
          <div className="pagination">
            <button onClick={() => paginate(1)} disabled={currentPage === 1}>
              First
            </button>
            <button
              onClick={() => paginate(currentPage - 1)}
              disabled={currentPage === 1}
            >
              Prev
            </button>
            {Array.from({ length: Math.min(5, totalPages) }, (_, i) => {
              const pageNum = Math.min(
                Math.max(currentPage - 2, 1) + i,
                totalPages
              );
              return (
                <button
                  key={pageNum}
                  onClick={() => paginate(pageNum)}
                  className={currentPage === pageNum ? "active" : ""}
                >
                  {pageNum}
                </button>
              );
            })}
            <button
              onClick={() => paginate(currentPage + 1)}
              disabled={currentPage === totalPages}
            >
              Next
            </button>
            <button
              onClick={() => paginate(totalPages)}
              disabled={currentPage === totalPages}
            >
              Last
            </button>
          </div>
        )}
      </WaiverWireContainer>
    </div>
  );
};

export default WaiverWire;
