import React from "react";
import styled from "styled-components";

const SettingsForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 15px;
  
  label {
    font-size: 1rem;
    color: #ddd;
  }
  
  input[type="checkbox"],
  input[type="number"] {
    margin-left: 10px;
  }
  
  button {
    background-color: #007bff;
    color: #fff;
    border: none;
    padding: 10px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    &:hover {
      background-color: #0056b3;
    }
  }
`;

const Settings = ({ leagueName }) => {
  return (
    <div className="settings-tab">
      <h2>League Settings</h2>
      <SettingsForm>
        <div>
          <label>
            League Name:
            <input type="text" defaultValue={leagueName} />
          </label>
        </div>
        <div>
          <label>
            Public League:
            <input type="checkbox" defaultChecked={true} />
          </label>
        </div>
        <div>
          <label>
            Max Teams:
            <input type="number" defaultValue={12} min={4} max={16} />
          </label>
        </div>
        <div>
          <label>
            Scoring Type:
            <select defaultValue="ppr">
              <option value="standard">Standard</option>
              <option value="ppr">PPR</option>
              <option value="halfppr">Half PPR</option>
            </select>
          </label>
        </div>
        <button type="submit">Save Settings</button>
      </SettingsForm>
    </div>
  );
};

export default Settings;
