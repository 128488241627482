import React, { useState, useEffect } from "react";
import styled from "styled-components";

const ChatContainer = styled.div`
  margin-top: 20px;
  .messages {
    height: 300px;
    overflow-y: auto;
    background-color: #2e2e3d;
    border-radius: 8px;
    padding: 10px;
    margin-bottom: 10px;
  }
  .message {
    margin-bottom: 10px;
    padding: 8px;
    border-radius: 5px;
    &.own-message {
      background-color: #007bff;
      color: white;
      align-self: flex-end;
    }
    &.other-message {
      background-color: #343a40;
      color: #ddd;
      align-self: flex-start;
    }
  }
  .message-input {
    display: flex;
    gap: 10px;
    input {
      flex-grow: 1;
      padding: 10px;
      border: none;
      border-radius: 5px;
      background-color: #2e2e3d;
      color: white;
    }
    button {
      padding: 10px 15px;
      background-color: #007bff;
      color: white;
      border: none;
      border-radius: 5px;
      cursor: pointer;
      &:hover {
        background-color: #0056b3;
      }
    }
  }
`;

const Chat = ({ supabase, league_id, user }) => {
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState("");

  useEffect(() => {
    if (!league_id) return;
    const fetchMessages = async () => {
      const { data, error } = await supabase
        .from("league_chat")
        .select("*")
        .eq("league_id", Number(league_id))
        .order("created_at", { ascending: true })
        .limit(50);
      if (!error) setMessages(data);
    };
    fetchMessages();
    const chatChannel = supabase
      .channel(`public:league_chat:league_id=eq.${league_id}`)
      .on(
        "postgres_changes",
        {
          event: "INSERT",
          schema: "public",
          table: "league_chat",
          filter: `league_id=eq.${league_id}`,
        },
        (payload) => {
          setMessages((prev) => [...prev, payload.new]);
        }
      )
      .subscribe();

    return () => {
      supabase.removeChannel(chatChannel);
    };
  }, [supabase, league_id]);

  const sendMessage = async () => {
    if (!newMessage.trim() || !user || !league_id) return;
    try {
      await supabase.from("league_chat").insert({
        league_id: league_id,
        user_id: user.id,
        message: newMessage,
      });
      setNewMessage("");
    } catch (error) {
      console.error("Error sending message:", error.message);
    }
  };

  return (
    <ChatContainer>
      <h2>League Chat</h2>
      <div className="messages">
        {messages.map((msg, index) => (
          <div
            key={index}
            className={`message ${
              msg.user_id === user?.id ? "own-message" : "other-message"
            }`}
          >
            <strong>
              {msg.user_id === user?.id
                ? "You"
                : msg.profiles?.username || "User"}
            </strong>
            : {msg.message}
          </div>
        ))}
      </div>
      <div className="message-input">
        <input
          type="text"
          placeholder="Type a message..."
          value={newMessage}
          onChange={(e) => setNewMessage(e.target.value)}
          onKeyPress={(e) => e.key === "Enter" && sendMessage()}
        />
        <button onClick={sendMessage}>Send</button>
      </div>
    </ChatContainer>
  );
};

export default Chat;
