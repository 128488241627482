import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useUser } from "./UserContext"; // Import the user context
import { FiHome, FiSettings, FiUsers, FiFileText, FiPlus } from "react-icons/fi"; // Icons
import { IoIosArrowDown, IoIosArrowForward } from "react-icons/io";
import { createClient } from "@supabase/supabase-js"; // Import Supabase client
import CreateFantasyLeague from "./CreateFantasyLeague"; // Import CreateFantasyLeague component
import CreateOrganization from "./CreateOrganization"; // Import CreateOrganization component
import OrganizationManagement from "./OrganizationManagement"; // Import OrganizationManagement component
import FantasyLeagueManagement from "./FantasyLeagues/FantasyLeagueManagement"; // Import FantasyLeagueManagement component
import styled from "styled-components";

// Styled components
const FantasyPage = styled.div`
  margin: 0;
  padding: 0;
  font-family: 'Roboto', sans-serif;
  background-color: #1a1a1a;
  color: white;
  overflow-x: hidden;
`;

const NavBar = styled.nav`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  background-color: #1a1a1a;
  color: white;
  border-bottom: 1px solid #333;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
  box-sizing: border-box;
`;

const Logo = styled.div`
  font-size: 24px;
  font-weight: bold;
  color: white;
  text-transform: uppercase;
`;

const SportsLinks = styled.ul`
  list-style: none;
  display: flex;
  align-items: center;
  gap: 20px;
  margin: 0;
  padding: 0;
  
  li {
    position: relative;
  }
`;

const HeaderDropdownButton = styled.button`
  background: none;
  border: none;
  color: white;
  font-size: 16px;
  padding: 10px;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
  border-radius: 3px;
  
  &:hover, &.active {
    background-color: #007bff;
    color: white;
  }
`;

const DropdownMenu = styled.div`
  position: absolute;
  top: 50px;
  left: 0;
  background-color: #2a2a2a;
  color: white;
  border-radius: 8px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.4);
  padding: 15px;
  z-index: 1000;
  width: 250px;
  opacity: 0;
  transform: translateY(-10px);
  pointer-events: none;
  transition: opacity 0.3s ease, transform 0.3s ease;
  
  h4 {
    font-size: 16px;
    margin-bottom: 10px;
    font-weight: bold;
    border-bottom: 1px solid #444;
    padding-bottom: 5px;
    color: #007bff;
  }
  
  ul {
    list-style: none;
    padding: 0;
    margin: 0;
    
    li {
      margin: 10px 0;
      
      a {
        text-decoration: none;
        color: white;
        font-size: 14px;
        transition: color 0.3s ease;
        
        &:hover {
          color: #007bff;
        }
      }
    }
  }
`;

const DropdownContainer = styled.li`
  &:hover ${DropdownMenu}, ${DropdownMenu}:hover {
    opacity: 1;
    transform: translateY(0);
    pointer-events: auto;
  }
`;

const AuthButtons = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

const LoginButton = styled.button`
  font-size: 16px;
  padding: 10px 15px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
  background-color: #333;
  color: white;
  border: 1px solid #4a4a4a;
  
  &:hover {
    background-color: #555;
    border-color: #555;
  }
`;

const SignupButton = styled.button`
  font-size: 16px;
  padding: 10px 15px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
  background-color: #007bff;
  color: white;
  border: 1px solid #007bff;
  
  &:hover {
    background-color: #0056b3;
    border-color: #0056b3;
  }
`;

const LogoutButton = styled(LoginButton)`
  background-color: #dc3545;
  border-color: #dc3545;
  
  &:hover {
    background-color: #bd2130;
    border-color: #bd2130;
  }
`;

const Username = styled.span`
  color: white;
  margin-right: 10px;
`;

const CustomSidebar = styled.div`
  position: fixed;
  top: 60px; /* Align with navbar */
  left: 0;
  height: calc(100% - 60px);
  width: 250px;
  background-color: #1a1a1a;
  color: white;
  padding: 20px 20px 20px 25px; /* Added left padding */
  border-right: 1px solid #333;
  box-sizing: border-box;
  overflow-y: auto;
  
  @media (max-width: 768px) {
    width: 200px;
  }
  
  @media (max-width: 480px) {
    width: 180px;
  }
  
  h3 {
    font-size: 18px;
    margin-bottom: 15px;
    color: #007bff;
    text-transform: uppercase;
    padding: 0;
    margin-left: 5px; /* Slight indentation for header */
  }
  
  ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  & > ul > li {
    display: flex;
    align-items: center;
    padding: 10px 0;
    cursor: pointer;
    color: white;
    font-size: 15px;
    transition: background-color 0.3s ease, color 0.3s ease;
    border-radius: 5px;
    
    &:hover {
      background-color: #2a2a2a; /* Subtle background highlight */
    }
    
    &.open {
      background-color: #1a1a1a; /* Subtle background change for open state */
    }
    
    .icon {
      margin-right: 10px;
      font-size: 16px;
      color: #007bff;
    }
    
    .arrow-icon {
      margin-left: auto; /* Ensures arrow icon stays at the end */
      font-size: 12px;
      color: #ccc;
      transition: color 0.3s ease;
    }
    
    &.open .arrow-icon {
      color: white; /* Highlight arrow icon for open state */
    }
  }
`;

const SidebarDropdownMenu = styled.ul`
  display: block; /* Changed to block */
  padding-left: 0; /* Remove left padding from menu */
  margin: 5px 0 0 0;
  list-style: none;
  
  ul {
    padding-left: 15px;
    margin-top: 5px;
  }
`;

const SportCategory = styled.div`
  display: block;
  padding: 5px 0;
  margin-left: 10px; /* Indent sport categories */
  
  &:hover {
    background-color: transparent;
    cursor: default;
  }
`;

const SportName = styled.span`
  font-size: 13px;
  color: #888;
  font-weight: bold;
  display: block;
  margin-bottom: 5px;
`;

const LeagueList = styled.ul`
  padding-left: 10px;
  margin: 0;
  list-style: none;
  
  li {
    padding: 8px 10px;
    font-size: 14px;
    color: #ddd;
    cursor: pointer;
    border-radius: 4px;
    transition: background-color 0.3s ease, color 0.3s ease;
    display: flex;
    flex-direction: column;
    
    &:hover {
      background-color: #2a2a2a;
      color: white;
    }
    
    &.active {
      background-color: #007bff;
      color: white;
    }
    
    .league-name {
      display: flex;
      align-items: center;
      font-weight: 500;
    }
    
    .draft-status {
      font-size: 12px;
      margin-top: 4px;
      color: #888;
      
      li.active & {
        color: rgba(255, 255, 255, 0.8);
      }
    }
  }
`;

const CreateLeague = styled.li`
  display: flex;
  align-items: center;
  padding: 10px 15px;
  margin-bottom: 10px;
  background-color: #007bff;
  color: white;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  
  .icon {
    margin-right: 10px;
    font-size: 16px;
    color: white;
  }
  
  &:hover {
    background-color: #0056b3;
  }
`;

const OrganizationCategory = styled(SportCategory)`
  margin-top: 15px;
  
  &:hover {
    background-color: transparent;
    cursor: default;
  }
`;

const OrganizationName = styled(SportName)`
  color: #888;
  margin-bottom: 10px;
`;

const NoOrganizations = styled.p`
  color: #888;
  font-size: 13px;
  font-style: italic;
  margin-left: 10px;
  margin-top: 5px;
`;

const MainContent = styled.div`
  margin-left: 250px;
  padding: 80px 20px 20px;
  min-height: calc(100vh - 60px);
  
  @media (max-width: 768px) {
    margin-left: 200px;
  }
  
  @media (max-width: 480px) {
    margin-left: 180px;
    padding: 70px 10px 10px;
  }
  
  .content {
    padding: 20px;
  }
  
  .league-content, .organization-content {
    background-color: #2a2a2a;
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
`;

const OwnerIndicator = styled.span`
  font-size: 11px;
  color: #888;
  font-style: italic;
  
  li.active & {
    color: rgba(255, 255, 255, 0.8);
  }
`;

// Initialize Supabase client
const supabase = createClient(
  process.env.REACT_APP_SUPABASE_URL,
  process.env.REACT_APP_SUPABASE_ANON_KEY
);

const Fantasy = () => {
  const { user, setUser } = useUser(); // Get user and setUser from context
  const navigate = useNavigate(); // Use React Router for navigation

  const handleLogout = () => {
    setUser(null); // Clear user from context
    navigate("/login"); // Redirect to login page
  };

  const [openMenus, setOpenMenus] = useState({ leagues: true }); // Open leagues menu by default
  const [selectedLeague, setSelectedLeague] = useState(null);
  const [selectedOrganization, setSelectedOrganization] = useState(null);
  const [leaguesBySport, setLeaguesBySport] = useState({});
  const [organizations, setOrganizations] = useState([]);
  const [showCreateLeague, setShowCreateLeague] = useState(false);
  const [showCreateOrganization, setShowCreateOrganization] = useState(false);

  // Fetch leagues and organizations from the database
  useEffect(() => {
    const fetchData = async () => {
      if (user) {
        try {
          console.log("Current user:", user);
          console.log("User ID being used for queries:", user.id);
          
          // Fetch leagues where user is the owner
          const { data: ownerLeagues, error: ownerLeaguesError } = await supabase
            .from("fantasy_leagues")
            .select(`
              *,
              sport:sport_id (sport_name)
            `)
            .eq("owner_id", user.id);

          if (ownerLeaguesError) {
            console.error("Error fetching owner leagues:", ownerLeaguesError);
            throw ownerLeaguesError;
          }
          
          console.log("Owner leagues:", ownerLeagues);

          // Fetch league_ids where user is a member
          const { data: leagueMemberships, error: leagueMembershipsError } = await supabase
            .from("league_members")
            .select("league_id")
            .eq("user_id", user.id);

          if (leagueMembershipsError) {
            console.error("Error fetching league memberships:", leagueMembershipsError);
            throw leagueMembershipsError;
          }
          
          console.log("League memberships:", leagueMemberships);

          const memberLeagueIds = leagueMemberships.map((membership) => membership.league_id);
          console.log("Member league IDs:", memberLeagueIds);

          // Fetch leagues where user is a member
          let memberLeagues = [];
          if (memberLeagueIds.length > 0) {
            const { data: memberLeaguesData, error: memberLeaguesError } = await supabase
              .from("fantasy_leagues")
              .select(`
                *,
                sport:sport_id (sport_name)
              `)
              .in("league_id", memberLeagueIds);

            if (memberLeaguesError) {
              console.error("Error fetching member leagues:", memberLeaguesError);
              throw memberLeaguesError;
            }
            
            console.log("Member leagues:", memberLeaguesData);
            memberLeagues = memberLeaguesData;
          }

          // Combine ownerLeagues and memberLeagues
          const allLeagues = [...ownerLeagues, ...memberLeagues];
          console.log("All leagues (before deduplication):", allLeagues);

          // Remove duplicates based on league_id
          const allLeaguesMap = {};
          allLeagues.forEach((league) => {
            allLeaguesMap[league.league_id] = league;
          });
          const uniqueLeagues = Object.values(allLeaguesMap);
          console.log("Unique leagues:", uniqueLeagues);

          // Fetch draft information for each league
          for (const league of uniqueLeagues) {
            try {
              // Convert league_id to a number and use .maybeSingle() instead of .single()
              const { data: draftData, error: draftError } = await supabase
                .from("league_drafts")
                .select("*")
                .eq("league_id", Number(league.league_id))
                .maybeSingle();
                
              if (!draftError && draftData) {
                league.draft_status = draftData.status;
                league.draft_time = draftData.draft_time;
              } else if (draftError) {
                console.error(`Error fetching draft for league ${league.league_id}:`, draftError.message);
              }
            } catch (error) {
              console.error(`Exception fetching draft for league ${league.league_id}:`, error.message);
            }
          }

          // Group leagues by sport
          const groupedLeagues = uniqueLeagues.reduce((acc, league) => {
            const sportName = league.sport?.sport_name || "Unknown Sport";
            if (!acc[sportName]) acc[sportName] = [];
            acc[sportName].push(league);
            return acc;
          }, {});
          
          console.log("Grouped leagues by sport:", groupedLeagues);
          setLeaguesBySport(groupedLeagues);
          
          // If no leagues were found, try fetching all leagues as a fallback for debugging
          if (Object.keys(groupedLeagues).length === 0) {
            console.log("No leagues found for user. Attempting to fetch all leagues as a fallback for debugging...");
            
            const { data: allLeaguesData, error: allLeaguesError } = await supabase
              .from("fantasy_leagues")
              .select(`
                *,
                sport:sport_id (sport_name)
              `);
              
            if (allLeaguesError) {
              console.error("Error fetching all leagues:", allLeaguesError);
            } else {
              console.log("All leagues in database:", allLeaguesData);
              
              if (allLeaguesData.length > 0) {
                console.log("There are leagues in the database, but none associated with this user.");
                console.log("Check if the user ID matches the owner_id format in the database.");
                console.log("User ID:", user.id);
                console.log("Example owner_id from database:", allLeaguesData[0].owner_id);
              } else {
                console.log("No leagues found in the database at all.");
              }
            }
          }

          // Fetch user's organizations
          const { data: ownedOrgs, error: ownedOrgsError } = await supabase
            .from("organizations")
            .select("*")
            .eq("owner_id", user.id);

          if (ownedOrgsError) throw ownedOrgsError;

          const { data: memberOrgs, error: memberOrgsError } = await supabase
            .from("orgmembers")
            .select("organization_id")
            .eq("user_id", user.id);

          if (memberOrgsError) throw memberOrgsError;

          const memberOrgIds = memberOrgs.map((org) => org.organization_id);
          const { data: orgDetails, error: orgDetailsError } = await supabase
            .from("organizations")
            .select("*")
            .in("organization_id", memberOrgIds);

          if (orgDetailsError) throw orgDetailsError;

          setOrganizations([...ownedOrgs, ...orgDetails]);
        } catch (error) {
          console.error("Error fetching data:", error.message);
        }
      }
    };

    fetchData();
  }, [user]);

  // Updated toggleMenu function to allow multiple open menus
  const toggleMenu = (menu) => {
    setOpenMenus((prevOpenMenus) => {
      const newOpenMenus = {
        ...prevOpenMenus,
        [menu]: !prevOpenMenus[menu],
      };
      console.log("Open menus:", newOpenMenus);
      return newOpenMenus;
    });
  };

  // Handle "Create League" click
  const handleCreateLeague = () => {
    setShowCreateLeague(true);
    setShowCreateOrganization(false);
    setSelectedLeague(null);
    setSelectedOrganization(null);
  };

  // Handle "Create Organization" click
  const handleCreateOrganization = () => {
    setShowCreateOrganization(true);
    setShowCreateLeague(false);
    setSelectedLeague(null);
    setSelectedOrganization(null);
  };

  // Handle "Home" click
  const handleHomeClick = () => {
    setSelectedLeague(null);
    setSelectedOrganization(null);
    setShowCreateLeague(false);
    setShowCreateOrganization(false);
  };

  // Render league items with draft status
  const renderLeagueItems = (leagues) => {
    return leagues.map((league) => (
      <li
        key={league.league_id}
        onClick={() => {
          setSelectedLeague(league);
          setShowCreateLeague(false);
          setShowCreateOrganization(false);
          setSelectedOrganization(null);
        }}
        className={selectedLeague?.league_id === league.league_id ? "active" : ""}
      >
        <div className="league-name">
          {league.league_name}
          {league.owner_id === user.id && <OwnerIndicator> (Owner)</OwnerIndicator>}
        </div>
        {league.draft_status && (
          <span className="draft-status">
            {league.draft_status === "Scheduled" && `Draft: ${new Date(league.draft_time).toLocaleDateString()}`}
            {league.draft_status === "In Progress" && "Draft in progress"}
            {league.draft_status === "Completed" && "Draft complete"}
          </span>
        )}
      </li>
    ));
  };

  return (
    <FantasyPage>
      {/* Navbar Section */}
      <NavBar>
        <Logo>MILKYSPORTS</Logo>
        <SportsLinks>
          {["NFL", "NBA", "MLB", "NHL", "Soccer", "NCAA", "Fantasy"].map((item) => (
            <DropdownContainer key={item} className="dropdown">
              <HeaderDropdownButton>{item}</HeaderDropdownButton>
              <DropdownMenu>
                <h4>{item} Options</h4>
                <ul>
                  <li>
                    <Link to={`/${item.toLowerCase()}/teams`}>Teams</Link>
                  </li>
                  <li>
                    <Link to={`/${item.toLowerCase()}/scores`}>Scores</Link>
                  </li>
                  <li>
                    <Link to={`/${item.toLowerCase()}/standings`}>Standings</Link>
                  </li>
                </ul>
              </DropdownMenu>
            </DropdownContainer>
          ))}
        </SportsLinks>
        <AuthButtons>
          {user ? (
            <>
              <Username>Welcome, {user.username}</Username>
              <LogoutButton onClick={handleLogout}>
                Log out
              </LogoutButton>
            </>
          ) : (
            <>
              <LoginButton onClick={() => navigate("/login")}>Log in</LoginButton>
              <SignupButton onClick={() => navigate("/create-account")}>Sign up</SignupButton>
            </>
          )}
        </AuthButtons>
      </NavBar>

      {/* Sidebar Section */}
      <CustomSidebar>
        <h3 className="section-header">Navigation</h3>
        <ul>
          <li onClick={handleHomeClick}>
            <FiHome className="icon" />
            Home
          </li>

          {/* Leagues Menu */}
          <li
            className={`dropdown ${openMenus["leagues"] ? "open" : ""}`}
            onClick={() => toggleMenu("leagues")}
          >
            <FiFileText className="icon" />
            <span>Leagues</span>
            <span className="arrow-icon">
              {openMenus["leagues"] ? <IoIosArrowDown /> : <IoIosArrowForward />}
            </span>
          </li>
          {openMenus["leagues"] && (
            <SidebarDropdownMenu>
              <CreateLeague onClick={handleCreateLeague}>
                <FiPlus className="icon" />
                <span>Create League</span>
              </CreateLeague>
              {Object.entries(leaguesBySport).map(([sportName, leagues]) => (
                <SportCategory key={sportName}>
                  <SportName>{sportName}</SportName>
                  <LeagueList>
                    {renderLeagueItems(leagues)}
                  </LeagueList>
                </SportCategory>
              ))}
            </SidebarDropdownMenu>
          )}

          {/* Organizations Menu */}
          <li
            className={`dropdown ${openMenus["organizations"] ? "open" : ""}`}
            onClick={() => toggleMenu("organizations")}
          >
            <FiUsers className="icon" />
            <span>Organizations</span>
            <span className="arrow-icon">
              {openMenus["organizations"] ? <IoIosArrowDown /> : <IoIosArrowForward />}
            </span>
          </li>
          {openMenus["organizations"] && (
            <SidebarDropdownMenu>
              <CreateLeague onClick={handleCreateOrganization}>
                <FiPlus className="icon" />
                <span>Create Organization</span>
              </CreateLeague>
              {organizations.length > 0 ? (
                <OrganizationCategory>
                  <OrganizationName>Your Organizations:</OrganizationName>
                  <LeagueList>
                    {organizations.map((org) => (
                      <li
                        key={org.organization_id}
                        onClick={() => {
                          setSelectedOrganization(org);
                          setShowCreateLeague(false);
                          setShowCreateOrganization(false);
                          setSelectedLeague(null);
                        }}
                      >
                        {org.name}
                        {org.owner_id === user.id && <OwnerIndicator> (Owner)</OwnerIndicator>}
                      </li>
                    ))}
                  </LeagueList>
                </OrganizationCategory>
              ) : (
                <NoOrganizations>No organizations found.</NoOrganizations>
              )}
            </SidebarDropdownMenu>
          )}

          {/* Settings Menu */}
          <li
            className={`dropdown ${openMenus["settings"] ? "open" : ""}`}
            onClick={() => toggleMenu("settings")}
          >
            <FiSettings className="icon" />
            <span>Settings</span>
            <span className="arrow-icon">
              {openMenus["settings"] ? <IoIosArrowDown /> : <IoIosArrowForward />}
            </span>
          </li>
          {openMenus["settings"] && (
            <SidebarDropdownMenu>
              <li onClick={() => navigate("/profile")}>Profile</li>
              <li onClick={() => navigate("/account-settings")}>Account Settings</li>
              <li onClick={() => navigate("/notifications")}>Notifications</li>
            </SidebarDropdownMenu>
          )}
        </ul>
      </CustomSidebar>

      {/* Main Content */}
      <MainContent>
        {showCreateLeague ? (
          <CreateFantasyLeague />
        ) : showCreateOrganization ? (
          <CreateOrganization />
        ) : selectedLeague ? (
          <FantasyLeagueManagement league={selectedLeague} />
        ) : selectedOrganization ? (
          <OrganizationManagement organization={selectedOrganization} />
        ) : (
          <div className="content">
            <h1>Welcome to Fantasy Sports</h1>
            <p>
              Select a league or organization from the sidebar to manage it, or create a new one.
            </p>
            
            {/* Debug information */}
            <div style={{ marginTop: '20px', padding: '10px', backgroundColor: '#333', borderRadius: '5px' }}>
              <h3>Debug Information</h3>
              <p>Leagues by Sport: {Object.keys(leaguesBySport).length > 0 ? 
                JSON.stringify(Object.keys(leaguesBySport)) : 'No leagues found'}</p>
              <p>Organizations: {organizations.length > 0 ? 
                JSON.stringify(organizations.map(org => org.name)) : 'No organizations found'}</p>
              <p>User: {user ? user.username : 'No user logged in'}</p>
            </div>
          </div>
        )}
      </MainContent>
    </FantasyPage>
  );
};

export default Fantasy;
