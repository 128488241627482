import React, { useState, useEffect } from 'react';
import { createClient } from '@supabase/supabase-js';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import backgroundImage from './images/vintagefb.png';

// Styled components
const CreateAccountWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: url(${backgroundImage}) no-repeat center center/cover;
`;

const FormContainer = styled.div`
  background-color: rgba(0, 0, 0, 0.8);
  padding: 30px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  text-align: center;
  width: 100%;
  max-width: 400px;
`;

const Title = styled.h2`
  color: #fff;
  margin-bottom: 20px;
  font-size: 28px;
  text-align: center;
  border-bottom: 2px solid #007bff;
  padding-bottom: 10px;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

const Label = styled.label`
  color: #ddd;
  font-size: 14px;
  align-self: flex-start;
`;

const Input = styled.input`
  width: 94%;
  padding: 12px;
  border: 1px solid #555;
  border-radius: 4px;
  background-color: #333;
  color: white;
  font-size: 14px;
`;

const Button = styled.button`
  width: 100%;
  padding: 12px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  
  &:hover {
    background-color: #0056b3;
  }
`;

const LinkText = styled.p`
  margin-top: 15px;
  font-size: 14px;
  color: #007bff;
  text-align: center;
  
  a {
    color: #007bff;
    text-decoration: none;
    
    &:hover {
      text-decoration: underline;
    }
  }
`;

const ErrorMessage = styled.p`
  color: red;
  margin-bottom: 10px;
  font-size: 14px;
`;

const SuccessMessage = styled.p`
  color: green;
  margin-bottom: 10px;
  font-size: 14px;
`;

const supabase = createClient(
  process.env.REACT_APP_SUPABASE_URL,
  process.env.REACT_APP_SUPABASE_ANON_KEY
);

const CreateAccount = () => {
  const navigate = useNavigate();
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [verifyPassword, setVerifyPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!firstName || !lastName || !username || !email || !password || !verifyPassword) {
      setErrorMessage('Please fill in all fields.');
      return;
    }

    if (password !== verifyPassword) {
      setErrorMessage('Passwords do not match.');
      return;
    }

    if (username.includes(' ')) {
      setErrorMessage('Username should not contain spaces.');
      return;
    }

    setLoading(true);

    try {
      // Create user in Supabase auth.users
      const { data: authUser, error: authError } = await supabase.auth.signUp({
        email,
        password,
      });

      if (authError) throw authError;

      // Insert user details into the profiles table
      const { error: profileError } = await supabase
        .from('profiles')
        .insert({
          id: authUser.user.id,
          first_name: firstName,
          last_name: lastName,
          username,
        });

      if (profileError) throw profileError;

      setSuccessMessage('Account created successfully! Please verify your email.');
      setTimeout(() => navigate('/login'), 3000);
    } catch (error) {
      console.error('Error creating account:', error.message);
      setErrorMessage(error.message || 'An error occurred. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (errorMessage || successMessage) {
      const timer = setTimeout(() => {
        setErrorMessage('');
        setSuccessMessage('');
      }, 5000);
      return () => clearTimeout(timer);
    }
  }, [errorMessage, successMessage]);

  return (
    <CreateAccountWrapper>
      <FormContainer>
        <Title>Create an Account</Title>
        {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
        {successMessage && <SuccessMessage>{successMessage}</SuccessMessage>}
        <Form onSubmit={handleSubmit}>
          <Label>First Name:</Label>
          <Input
            type="text"
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
            required
            disabled={loading}
          />
          <Label>Last Name:</Label>
          <Input
            type="text"
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
            required
            disabled={loading}
          />
          <Label>Username:</Label>
          <Input
            type="text"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            required
            disabled={loading}
          />
          <Label>Email:</Label>
          <Input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
            disabled={loading}
          />
          <Label>Password:</Label>
          <Input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
            disabled={loading}
          />
          <Label>Verify Password:</Label>
          <Input
            type="password"
            value={verifyPassword}
            onChange={(e) => setVerifyPassword(e.target.value)}
            required
            disabled={loading}
          />
          <Button type="submit" disabled={loading}>
            {loading ? 'Creating...' : 'Create Account'}
          </Button>
        </Form>
        <LinkText>
          Already have an account? <a href="/login">Login</a>
        </LinkText>
      </FormContainer>
    </CreateAccountWrapper>
  );
};

export default CreateAccount;
