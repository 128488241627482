// OrganizationManagement.js
import React, { useState, useEffect } from "react";
import { createClient } from "@supabase/supabase-js"; // Import Supabase client
import styled from "styled-components"; // Import styled-components

// Styled components
const Container = styled.div`
  padding: 20px;
  color: white;
  font-family: 'Roboto', sans-serif;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  
  h1 {
    font-size: 2rem;
    margin: 0;
    color: #007bff;
  }
`;

const Section = styled.div`
  background-color: #2a2a2a;
  border-radius: 8px;
  padding: 20px;
  margin-bottom: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  
  h2 {
    color: #007bff;
    margin-top: 0;
    margin-bottom: 15px;
    font-size: 1.5rem;
    border-bottom: 1px solid #444;
    padding-bottom: 10px;
  }
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
  
  th, td {
    padding: 12px 15px;
    text-align: left;
    border-bottom: 1px solid #444;
  }
  
  th {
    background-color: #333;
    color: #007bff;
  }
  
  tr:hover {
    background-color: #333;
  }
`;

const Select = styled.select`
  background-color: #333;
  color: white;
  border: 1px solid #444;
  padding: 8px 12px;
  border-radius: 4px;
  cursor: pointer;
  
  &:focus {
    outline: none;
    border-color: #007bff;
  }
  
  option {
    background-color: #333;
  }
`;

const SportsList = styled.ul`
  list-style: none;
  padding: 0;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 15px;
`;

const TeamsList = styled.ul`
  list-style: none;
  padding: 0;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 15px;
`;

const PlayersList = styled.ul`
  list-style: none;
  padding: 0;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 15px;
`;

const Card = styled.li`
  background-color: #333;
  border-radius: 8px;
  padding: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  transition: all 0.3s ease;
  
  &:hover {
    background-color: #444;
    transform: translateY(-3px);
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.3);
  }
  
  &.selected {
    border: 2px solid #007bff;
  }
  
  span {
    font-weight: bold;
    color: white;
  }
`;

const ButtonGroup = styled.div`
  display: flex;
  gap: 10px;
  margin-bottom: 20px;
`;

const Button = styled.button`
  padding: 10px 15px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-weight: bold;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  gap: 5px;
  
  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
`;

const AddButton = styled(Button)`
  background-color: #28a745;
  color: white;
  
  &:hover {
    background-color: #218838;
  }
`;

const DeleteButton = styled(Button)`
  background-color: #dc3545;
  color: white;
  
  &:hover {
    background-color: #c82333;
  }
`;

const NoItemsMessage = styled.p`
  color: #aaa;
  font-style: italic;
  text-align: center;
  margin: 20px 0;
`;

// Initialize Supabase client
const supabase = createClient(
  process.env.REACT_APP_SUPABASE_URL,
  process.env.REACT_APP_SUPABASE_ANON_KEY
);

const OrganizationManagement = ({ organization, user }) => {
  const [organizationMembers, setOrganizationMembers] = useState([]);
  const [organizationSports, setOrganizationSports] = useState([]);
  const [selectedSport, setSelectedSport] = useState(null);
  const [organizationTeams, setOrganizationTeams] = useState([]);
  const [selectedTeam, setSelectedTeam] = useState(null);
  const [teamPlayers, setTeamPlayers] = useState([]);

  // Fetch organization data when component mounts or organization changes
  useEffect(() => {
    const fetchOrganizationData = async () => {
      if (organization) {
        try {
          // Fetch organization members
          const { data: members, error: membersError } = await supabase
            .from("orgmembers")
            .select("*, users:user_id ( username, email )")
            .eq("organization_id", organization.organization_id);

          if (membersError) throw membersError;
          setOrganizationMembers(members);

          // Fetch sports offered by the organization
          const { data: sports, error: sportsError } = await supabase
            .from("sports")
            .select("*")
            .eq("organization_id", organization.organization_id);

          if (sportsError) throw sportsError;
          setOrganizationSports(sports);

          // Reset selected sport, team, and team players
          setSelectedSport(null);
          setOrganizationTeams([]);
          setSelectedTeam(null);
          setTeamPlayers([]);
        } catch (error) {
          console.error("Error fetching organization data:", error.message);
        }
      }
    };

    fetchOrganizationData();
  }, [organization]);

  // Handle selecting a sport
  const handleSportSelect = async (sport) => {
    setSelectedSport(sport);
    setSelectedTeam(null);
    setTeamPlayers([]);
    try {
      // Fetch teams for the selected sport in the organization
      const { data: teams, error: teamsError } = await supabase
        .from("orgteams")
        .select("*")
        .eq("organization_id", organization.organization_id)
        .eq("sport_id", sport.sport_id);

      if (teamsError) throw teamsError;
      setOrganizationTeams(teams);
    } catch (error) {
      console.error("Error fetching teams:", error.message);
    }
  };

  // Handle selecting a team
  const handleTeamSelect = async (team) => {
    setSelectedTeam(team);
    try {
      // Fetch players for the selected team
      const { data: players, error: playersError } = await supabase
        .from("orgteamplayers")
        .select("*")
        .eq("team_id", team.team_id);

      if (playersError) throw playersError;
      setTeamPlayers(players);
    } catch (error) {
      console.error("Error fetching team players:", error.message);
    }
  };

  // Handle changing member permissions
  const handlePermissionChange = async (memberId, newRole) => {
    try {
      const { error } = await supabase
        .from("orgmembers")
        .update({ role: newRole })
        .eq("member_id", memberId);

      if (error) throw error;

      // Update the local state
      setOrganizationMembers((prevMembers) =>
        prevMembers.map((member) =>
          member.member_id === memberId ? { ...member, role: newRole } : member
        )
      );
    } catch (error) {
      console.error("Error updating member role:", error.message);
    }
  };

  // Check if user is organization owner
  const isOwner = user && organization && user.id === organization.owner_id;

  // Functions to add/delete sports, teams, and players
  // Add Sport
  const handleAddSport = async () => {
    const sportName = prompt("Enter the sport name:");
    if (sportName) {
      try {
        const { data, error } = await supabase.from("sports").insert([
          {
            organization_id: organization.organization_id,
            sport_name: sportName,
            created_at: new Date(),
            is_custom: true,
          },
        ]);
        if (error) throw error;
        setOrganizationSports([...organizationSports, ...data]);
      } catch (error) {
        console.error("Error adding sport:", error.message);
      }
    }
  };

  // Delete Sport
  const handleDeleteSport = async (sportId) => {
    if (window.confirm("Are you sure you want to delete this sport?")) {
      try {
        const { error } = await supabase
          .from("sports")
          .delete()
          .eq("sport_id", sportId);

        if (error) throw error;

        setOrganizationSports(
          organizationSports.filter((sport) => sport.sport_id !== sportId)
        );
        if (selectedSport && selectedSport.sport_id === sportId) {
          setSelectedSport(null);
          setOrganizationTeams([]);
          setSelectedTeam(null);
          setTeamPlayers([]);
        }
      } catch (error) {
        console.error("Error deleting sport:", error.message);
      }
    }
  };

  // Add Team
  const handleAddTeam = async () => {
    const teamName = prompt("Enter the team name:");
    if (teamName && selectedSport) {
      try {
        const { data, error } = await supabase.from("orgteams").insert([
          {
            organization_id: organization.organization_id,
            sport_id: selectedSport.sport_id,
            name: teamName,
            created_at: new Date(),
          },
        ]);
        if (error) throw error;
        setOrganizationTeams([...organizationTeams, ...data]);
      } catch (error) {
        console.error("Error adding team:", error.message);
      }
    }
  };

  // Delete Team
  const handleDeleteTeam = async (teamId) => {
    if (window.confirm("Are you sure you want to delete this team?")) {
      try {
        const { error } = await supabase
          .from("orgteams")
          .delete()
          .eq("team_id", teamId);

        if (error) throw error;

        setOrganizationTeams(
          organizationTeams.filter((team) => team.team_id !== teamId)
        );
        if (selectedTeam && selectedTeam.team_id === teamId) {
          setSelectedTeam(null);
          setTeamPlayers([]);
        }
      } catch (error) {
        console.error("Error deleting team:", error.message);
      }
    }
  };

  // Add Player
  const handleAddPlayer = async () => {
    const playerName = prompt("Enter the player's name:");
    const position = prompt("Enter the player's position:");
    if (playerName && position && selectedTeam) {
      try {
        const { data, error } = await supabase.from("orgteamplayers").insert([
          {
            team_id: selectedTeam.team_id,
            organization_id: organization.organization_id,
            player_name: playerName,
            position: position,
            created_at: new Date(),
          },
        ]);
        if (error) throw error;
        setTeamPlayers([...teamPlayers, ...data]);
      } catch (error) {
        console.error("Error adding player:", error.message);
      }
    }
  };

  // Delete Player
  const handleDeletePlayer = async (playerId) => {
    if (window.confirm("Are you sure you want to delete this player?")) {
      try {
        const { error } = await supabase
          .from("orgteamplayers")
          .delete()
          .eq("player_id", playerId);

        if (error) throw error;

        setTeamPlayers(
          teamPlayers.filter((player) => player.player_id !== playerId)
        );
      } catch (error) {
        console.error("Error deleting player:", error.message);
      }
    }
  };

  if (!organization) {
    return <Container>No organization selected.</Container>;
  }

  return (
    <Container>
      <Header>
        <h1>{organization.organization_name}</h1>
      </Header>

      <Section>
        <h2>Members Management</h2>
        {organizationMembers.length > 0 ? (
          <Table>
            <thead>
              <tr>
                <th>Username</th>
                <th>Email</th>
                <th>Role</th>
                {isOwner && <th>Actions</th>}
              </tr>
            </thead>
            <tbody>
              {organizationMembers.map((member) => (
                <tr key={member.member_id}>
                  <td>{member.users?.username || "Unknown"}</td>
                  <td>{member.users?.email || "Unknown"}</td>
                  <td>
                    {isOwner && member.user_id !== organization.owner_id ? (
                      <Select
                        value={member.role}
                        onChange={(e) =>
                          handlePermissionChange(member.member_id, e.target.value)
                        }
                      >
                        <option value="admin">Admin</option>
                        <option value="coach">Coach</option>
                        <option value="member">Member</option>
                      </Select>
                    ) : (
                      member.role
                    )}
                  </td>
                  {isOwner && (
                    <td>
                      {member.user_id !== organization.owner_id && (
                        <DeleteButton
                          onClick={() => {
                            if (
                              window.confirm(
                                "Are you sure you want to remove this member?"
                              )
                            ) {
                              // Handle member removal
                            }
                          }}
                        >
                          Remove
                        </DeleteButton>
                      )}
                    </td>
                  )}
                </tr>
              ))}
            </tbody>
          </Table>
        ) : (
          <NoItemsMessage>No members found.</NoItemsMessage>
        )}
      </Section>

      <Section>
        <h2>Sports Management</h2>
        {isOwner && (
          <ButtonGroup>
            <AddButton onClick={handleAddSport}>Add Sport</AddButton>
          </ButtonGroup>
        )}
        {organizationSports.length > 0 ? (
          <SportsList>
            {organizationSports.map((sport) => (
              <Card
                key={sport.sport_id}
                className={
                  selectedSport && selectedSport.sport_id === sport.sport_id
                    ? "selected"
                    : ""
                }
                onClick={() => handleSportSelect(sport)}
              >
                <span>{sport.sport_name}</span>
                {isOwner && (
                  <DeleteButton
                    onClick={(e) => {
                      e.stopPropagation();
                      handleDeleteSport(sport.sport_id);
                    }}
                  >
                    Delete
                  </DeleteButton>
                )}
              </Card>
            ))}
          </SportsList>
        ) : (
          <NoItemsMessage>No sports found.</NoItemsMessage>
        )}
      </Section>

      {selectedSport && (
        <Section>
          <h2>Teams for {selectedSport.sport_name}</h2>
          {isOwner && (
            <ButtonGroup>
              <AddButton onClick={handleAddTeam}>Add Team</AddButton>
            </ButtonGroup>
          )}
          {organizationTeams.length > 0 ? (
            <TeamsList>
              {organizationTeams.map((team) => (
                <Card
                  key={team.team_id}
                  className={
                    selectedTeam && selectedTeam.team_id === team.team_id
                      ? "selected"
                      : ""
                  }
                  onClick={() => handleTeamSelect(team)}
                >
                  <span>{team.name}</span>
                  {isOwner && (
                    <DeleteButton
                      onClick={(e) => {
                        e.stopPropagation();
                        handleDeleteTeam(team.team_id);
                      }}
                    >
                      Delete
                    </DeleteButton>
                  )}
                </Card>
              ))}
            </TeamsList>
          ) : (
            <NoItemsMessage>No teams found for this sport.</NoItemsMessage>
          )}
        </Section>
      )}

      {selectedTeam && (
        <Section>
          <h2>Players for {selectedTeam.name}</h2>
          {isOwner && (
            <ButtonGroup>
              <AddButton onClick={handleAddPlayer}>Add Player</AddButton>
            </ButtonGroup>
          )}
          {teamPlayers.length > 0 ? (
            <PlayersList>
              {teamPlayers.map((player) => (
                <Card key={player.player_id}>
                  <span>{player.player_name} - {player.position}</span>
                  {isOwner && (
                    <DeleteButton
                      onClick={() => handleDeletePlayer(player.player_id)}
                    >
                      Delete
                    </DeleteButton>
                  )}
                </Card>
              ))}
            </PlayersList>
          ) : (
            <NoItemsMessage>No players found for this team.</NoItemsMessage>
          )}
        </Section>
      )}
    </Container>
  );
};

export default OrganizationManagement;
