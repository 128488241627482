// JoinLeague.js

import React, { useState, useEffect, useCallback } from 'react';
import { useUser } from './UserContext'; // Import user context
import { createClient } from '@supabase/supabase-js'; // Import Supabase client
import { useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';

// Styled components
const JoinLeagueContainer = styled.div`
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  color: #fff;
`;

const Title = styled.h1`
  text-align: center;
  margin-bottom: 30px;
  color: #007bff;
`;

const JoinLeagueForm = styled.form`
  display: flex;
  flex-direction: column;
`;

const FormGroup = styled.div`
  margin-bottom: 25px;
`;

const Label = styled.label`
  display: block;
  font-size: 18px;
  margin-bottom: 10px;
`;

const Input = styled.input`
  width: 100%;
  padding: 10px;
  border: 1px solid #444;
  border-radius: 5px;
  background-color: #1a1a1a;
  color: #fff;
  font-size: 16px;
  
  &::placeholder {
    color: #888;
  }
`;

const JoinButton = styled.button`
  padding: 15px;
  background-color: #28a745;
  border: none;
  border-radius: 5px;
  color: #fff;
  font-size: 18px;
  cursor: pointer;
  
  &:hover {
    background-color: #218838;
  }
`;

const SuccessMessage = styled.p`
  color: #28a745;
  font-size: 16px;
  margin-top: 20px;
`;

const ErrorMessage = styled.p`
  color: #dc3545;
  font-size: 16px;
  margin-top: 20px;
`;

// Initialize Supabase client
const supabase = createClient(
  process.env.REACT_APP_SUPABASE_URL,
  process.env.REACT_APP_SUPABASE_ANON_KEY
);

const JoinLeague = () => {
  const { user } = useUser();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const initialCode = queryParams.get('code') || '';
  const navigate = useNavigate();

  const [invitationCode, setInvitationCode] = useState(initialCode);
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');

  const handleJoinLeague = useCallback(async (e) => {
    if (e) e.preventDefault();

    if (!invitationCode.trim()) {
      setErrorMessage('Please enter a valid invitation code.');
      return;
    }

    try {
      // Fetch the league using the invitation code
      const { data: leagueData, error: leagueError } = await supabase
        .from('fantasy_leagues')
        .select('*')
        .eq('invitation_code', invitationCode)
        .single();

      if (leagueError || !leagueData) {
        setErrorMessage('Invalid invitation code. Please try again.');
        return;
      }

      // Check if the user is already a member
      const { data: existingMember } = await supabase
        .from('league_members')
        .select('*')
        .eq('league_id', leagueData.league_id)
        .eq('user_id', user.id)
        .single();

      if (existingMember) {
        setErrorMessage('You are already a member of this league.');
        return;
      }

      // Ensure the league has capacity
      const { data: members, error: membersError } = await supabase
        .from('league_members')
        .select('*')
        .eq('league_id', leagueData.league_id);

      if (membersError) {
        setErrorMessage('Failed to join league. Please try again.');
        return;
      }

      if (members.length >= leagueData.num_teams) {
        setErrorMessage('This league is full and cannot accept more members.');
        return;
      }

      // Fetch user profile to get username
      const { data: profileData, error: profileError } = await supabase
        .from('profiles')
        .select('username')
        .eq('id', user.id)
        .single();
        
      if (profileError) {
        console.error("Error fetching profile:", profileError);
        // Continue anyway, we'll use a default team name
      }
      
      const username = profileData?.username || 'Team';

      // Add the user to the league members table
      const { error: memberError } = await supabase
        .from('league_members')
        .insert([
          {
            league_id: leagueData.league_id,
            user_id: user.id,
            joined_at: new Date().toISOString()
          }
        ]);

      if (memberError) {
        setErrorMessage('Failed to join league. Please try again.');
        return;
      }
      
      // Create a team for the user in the league_teams table
      const { error: teamError } = await supabase
        .from('league_teams')
        .insert([
          {
            league_id: leagueData.league_id,
            user_id: user.id,
            team_name: `${username}'s Team`,
            wins: 0,
            losses: 0,
            ties: 0,
            points_for: 0,
            points_against: 0
          }
        ]);
        
      if (teamError) {
        console.error("Error creating team:", teamError);
        // Continue anyway as this is not critical for joining
      }

      setSuccessMessage(`Successfully joined ${leagueData.league_name}!`);
      setInvitationCode('');

      // Redirect to the fantasy page after a short delay
      setTimeout(() => {
        navigate('/fantasy');
      }, 2000);
    } catch (error) {
      console.error('Error joining league:', error.message);
      setErrorMessage('Failed to join league. Please try again.');
    }
  }, [invitationCode, user, navigate]);

  useEffect(() => {
    if (invitationCode) {
      handleJoinLeague();
    }
  }, [invitationCode, handleJoinLeague]);

  return (
    <JoinLeagueContainer>
      <Title>Join a Fantasy League</Title>
      <JoinLeagueForm onSubmit={handleJoinLeague}>
        <FormGroup>
          <Label>Invitation Code:</Label>
          <Input
            type="text"
            value={invitationCode}
            onChange={(e) => setInvitationCode(e.target.value)}
            placeholder="Enter invitation code"
            required
          />
        </FormGroup>
        <JoinButton type="submit">Join League</JoinButton>
      </JoinLeagueForm>
      {successMessage && <SuccessMessage>{successMessage}</SuccessMessage>}
      {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
    </JoinLeagueContainer>
  );
};

export default JoinLeague;
