import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useUser } from "./UserContext";
import styled from "styled-components";

// Styled components
const NavbarContainer = styled.div`
  .navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    background-color: #1a1a1a;
    color: white;
    border-bottom: 1px solid #333;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1000;
    box-sizing: border-box;
  }
`;

const Logo = styled.div`
  font-size: 24px;
  font-weight: bold;
  color: white;
  text-transform: uppercase;
  cursor: pointer;
  
  @media (max-width: 480px) {
    font-size: 20px;
  }
`;

const MobileMenuToggle = styled.button`
  display: none;
  background: none;
  border: none;
  color: white;
  font-size: 24px;
  cursor: pointer;
  
  @media (max-width: 768px) {
    display: block;
  }
`;

const SportsLinks = styled.ul`
  list-style: none;
  display: flex;
  align-items: center;
  gap: 20px;
  margin: 0;
  padding: 0;
  
  &.active {
    display: flex;
  }
  
  @media (max-width: 768px) {
    display: none;
    flex-direction: column;
    position: absolute;
    top: 60px;
    left: 0;
    background-color: #1a1a1a;
    width: 100%;
    padding: 10px 0;
    border-top: 1px solid #333;
    
    &.active {
      display: flex;
    }
  }
`;

const DropdownItem = styled.li`
  position: relative;
`;

const DropdownButton = styled.button`
  background: none;
  border: none;
  color: white;
  font-size: 16px;
  padding: 10px;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
  border-radius: 3px;
  
  &:hover {
    background-color: #007bff;
  }
  
  @media (max-width: 768px) {
    width: 100%;
    text-align: left;
  }
`;

const DropdownMenu = styled.div`
  position: absolute;
  top: 50px;
  left: 0;
  background-color: #2a2a2a;
  color: white;
  border-radius: 8px;
  padding: 15px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.4);
  
  ul {
    list-style: none;
    padding: 0;
    margin: 0;
    
    li {
      margin: 10px 0;
      
      a {
        text-decoration: none;
        color: white;
        font-size: 14px;
        transition: color 0.3s ease;
        
        &:hover {
          color: #007bff;
        }
      }
    }
  }
`;

const AuthButtons = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  
  @media (max-width: 480px) {
    .login-button, .signup-button {
      font-size: 14px;
      padding: 8px 12px;
    }
  }
`;

const UserMenu = styled.div`
  position: relative;
  cursor: pointer;
  color: white;
`;

const Username = styled.span`
  color: white;
`;

const UserDropdown = styled.div`
  display: none;
  position: absolute;
  top: 100%;
  right: 0;
  background-color: #2a2a2a;
  border-radius: 8px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.4);
  padding: 10px 0;
  z-index: 1000;
  
  &.active {
    display: block;
  }
  
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    
    li {
      padding: 10px 20px;
      cursor: pointer;
      transition: background-color 0.3s ease;
      
      &:hover {
        background-color: #007bff;
        color: white;
      }
      
      button {
        background: none;
        border: none;
        color: white;
        width: 100%;
        text-align: left;
        font-size: 14px;
        cursor: pointer;
        padding: 0;
        
        &:hover {
          color: white;
        }
      }
    }
  }
`;

const AuthButton = styled.button`
  background-color: ${props => props.primary ? '#007bff' : 'transparent'};
  color: white;
  border: ${props => props.primary ? 'none' : '1px solid white'};
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  
  &:hover {
    background-color: ${props => props.primary ? '#0056b3' : 'rgba(255, 255, 255, 0.1)'};
  }
`;

const Navbar = () => {
  const { user, setUser } = useUser();
  const navigate = useNavigate();
  const [dropdownOpen, setDropdownOpen] = useState({});
  const [userMenuOpen, setUserMenuOpen] = useState(false); // State for user dropdown menu
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  const toggleDropdown = (key, isOpen) => {
    setDropdownOpen((prev) => ({ ...prev, [key]: isOpen }));
  };

  const sports = [
    { name: "NFL", links: ["Teams", "Scores", "Standings"], path: "/nfl" },
    { name: "NBA", links: ["Teams", "Scores", "Standings"], path: "/nba" },
    { name: "MLB", links: ["Teams", "Scores", "Standings"], path: "/mlb" },
    { name: "NHL", links: ["Teams", "Scores", "Standings"], path: "/nhl" },
    { name: "Soccer", links: ["Teams", "Scores", "Standings"], path: "/soccer" },
    { name: "NCAA", links: ["Teams", "Scores", "Standings"], path: "/ncaa" },
    {
      name: "Fantasy",
      links: ["Draft", "Tips", "Leagues", "Create Your Organization"],
      path: "/fantasy",
    },
  ];

  const handleLogout = () => {
    setUser(null);
    navigate("/login");
  };

  return (
    <NavbarContainer>
      <nav className="navbar">
        <Logo onClick={() => navigate("/")}>
          MILKYSPORTS
        </Logo>
        <MobileMenuToggle
          onClick={() => setMobileMenuOpen((prev) => !prev)}
        >
          ☰
        </MobileMenuToggle>
        <SportsLinks className={mobileMenuOpen ? "active" : ""}>
          {sports.map((sport) => (
            <DropdownItem
              key={sport.name}
              onMouseEnter={() => toggleDropdown(sport.name, true)}
              onMouseLeave={() => toggleDropdown(sport.name, false)}
            >
              <DropdownButton
                onClick={() => navigate(sport.path || `/${sport.name.toLowerCase()}`)}
              >
                {sport.name}
              </DropdownButton>
              {dropdownOpen[sport.name] && (
                <DropdownMenu>
                  <h4>{sport.name} Options</h4>
                  <ul>
                    {sport.links.map((link) => (
                      <li key={link}>
                        <Link
                          to={
                            link === "Create Your Organization"
                              ? "/create-organization"
                              : `/${sport.name.toLowerCase()}/${link.toLowerCase()}`
                          }
                        >
                          {link}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </DropdownMenu>
              )}
            </DropdownItem>
          ))}
        </SportsLinks>
        <AuthButtons>
          {user ? (
            <UserMenu
              onMouseEnter={() => setUserMenuOpen(true)}
              onMouseLeave={() => setUserMenuOpen(false)}
            >
              <Username>Welcome, {user.username}</Username>
              <UserDropdown className={userMenuOpen ? "active" : ""}>
                <ul>
                  <li>
                    <button onClick={() => navigate("/profile")}>Profile</button>
                  </li>
                  <li>
                    <button onClick={() => navigate("/settings")}>Settings</button>
                  </li>
                  <li>
                    <button onClick={handleLogout}>Log out</button>
                  </li>
                </ul>
              </UserDropdown>
            </UserMenu>
          ) : (
            <>
              <AuthButton onClick={() => navigate("/login")}>
                Log in
              </AuthButton>
              <AuthButton primary onClick={() => navigate("/create-account")}>
                Sign up
              </AuthButton>
            </>
          )}
        </AuthButtons>
      </nav>
    </NavbarContainer>
  );
};

export default Navbar;
