// Updated CreateFantasyLeague.js

import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useUser } from './UserContext'; // Import user context
import { createClient } from '@supabase/supabase-js'; // Import Supabase client
import styled from 'styled-components';

// Styled components
const Container = styled.div`
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  color: #fff;
`;

const Title = styled.h1`
  text-align: center;
  margin-bottom: 30px;
  color: #007bff;
`;

const StepIndicators = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
`;

const Step = styled.div`
  flex: 1;
  text-align: center;
  padding: 10px;
  border-bottom: 2px solid #444;
  color: #ccc;
  font-weight: bold;
  
  &.active {
    border-color: #007bff;
    color: #007bff;
  }
`;

const AccessCodeSection = styled.div`
  margin-bottom: 25px;
  
  label {
    font-size: 18px;
    display: block;
    margin-bottom: 10px;
  }
`;

const AccessCodeInput = styled.div`
  display: flex;
  gap: 10px;
  
  input[type="text"] {
    flex-grow: 1;
    padding: 10px;
    border: 1px solid #444;
    border-radius: 5px;
    background-color: #1a1a1a;
    color: #fff;
    font-size: 16px;
    
    &::placeholder {
      color: #888;
    }
  }
  
  button {
    padding: 10px 20px;
    background-color: #007bff;
    border: none;
    border-radius: 5px;
    color: #fff;
    font-size: 16px;
    cursor: pointer;
    
    &:hover {
      background-color: #0056b3;
    }
  }
`;

const LeagueForm = styled.div`
  display: flex;
  flex-direction: column;
`;

const FormGroup = styled.div`
  margin-bottom: 25px;
  
  label {
    display: block;
    font-size: 18px;
    margin-bottom: 10px;
  }
  
  input[type="text"] {
    width: 100%;
    padding: 10px;
    border: 1px solid #444;
    border-radius: 5px;
    background-color: #1a1a1a;
    color: #fff;
    font-size: 16px;
    
    &::placeholder {
      color: #888;
    }
  }
`;

const SportsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
`;

const SportBubble = styled.div`
  padding: 10px 20px;
  background-color: #2a2a2a;
  border: 1px solid #444;
  border-radius: 20px;
  cursor: pointer;
  transition: background-color 0.3s ease, border-color 0.3s ease;
  color: #ccc;
  
  &.selected {
    background-color: #007bff;
    border-color: #007bff;
    color: #fff;
  }
  
  &:hover {
    background-color: #007bff;
    border-color: #007bff;
    color: #fff;
  }
`;

const ButtonGroup = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
`;

const Button = styled.button`
  padding: 12px 20px;
  border: none;
  border-radius: 5px;
  color: #fff;
  font-size: 16px;
  cursor: pointer;
`;

const BackButton = styled(Button)`
  background-color: #6c757d;
  
  &:hover {
    background-color: #5a6268;
  }
`;

const NextButton = styled(Button)`
  background-color: #007bff;
  
  &:hover {
    background-color: #0056b3;
  }
`;

const CreateButton = styled(Button)`
  padding: 15px;
  background-color: #28a745;
  font-size: 18px;
  margin-top: 20px;
  
  &:hover {
    background-color: #218838;
  }
`;

const SuccessMessage = styled.div`
  color: #28a745;
  font-size: 16px;
  margin-top: 20px;
`;

const ErrorMessage = styled.div`
  color: #dc3545;
  font-size: 16px;
  margin-top: 20px;
`;

const FootballSettings = styled.div`
  max-width: 600px;
  margin: 0 auto;
  
  h2 {
    text-align: center;
    margin-bottom: 20px;
    color: #007bff;
  }
  
  .form-group {
    margin-bottom: 20px;
  }
  
  label {
    display: block;
    font-size: 18px;
    margin-bottom: 10px;
  }
  
  select {
    width: 100%;
    padding: 10px;
    border: 1px solid #444;
    border-radius: 5px;
    background-color: #1a1a1a;
    color: #fff;
    font-size: 16px;
  }
`;

const Confirmation = styled.div`
  max-width: 600px;
  margin: 0 auto;
  text-align: center;
  
  h2 {
    margin-bottom: 20px;
    color: #007bff;
  }
  
  p {
    font-size: 18px;
    margin-bottom: 10px;
  }
`;

const InvitationSection = styled.div`
  margin-top: 30px;
  padding: 20px;
  background-color: rgba(0, 123, 255, 0.1);
  border-radius: 8px;
  
  h3 {
    margin-bottom: 15px;
    color: #007bff;
  }
  
  .invitation-code {
    font-family: monospace;
    padding: 10px;
    background-color: #1a1a1a;
    border: 1px solid #444;
    border-radius: 5px;
    margin-bottom: 20px;
  }
  
  .email-form {
    display: flex;
    gap: 10px;
    margin-top: 15px;
    
    input {
      flex-grow: 1;
      padding: 10px;
      border: 1px solid #444;
      border-radius: 5px;
      background-color: #1a1a1a;
      color: #fff;
    }
    
    button {
      padding: 10px 20px;
      background-color: #007bff;
      border: none;
      border-radius: 5px;
      color: #fff;
      cursor: pointer;
      
      &:hover {
        background-color: #0056b3;
      }
    }
  }
`;

// Initialize Supabase client
const supabase = createClient(
  process.env.REACT_APP_SUPABASE_URL,
  process.env.REACT_APP_SUPABASE_ANON_KEY
);

const CreateFantasyLeague = () => {
  const { user } = useUser();
  const navigate = useNavigate();

  // Redirect to login if not logged in
  useEffect(() => {
    if (!user) {
      alert('Please log in to create a fantasy league.');
      navigate('/login');
    }
  }, [user, navigate]);

  // Form steps
  const [currentStep, setCurrentStep] = useState(1);

  // Form fields
  const [leagueName, setLeagueName] = useState('');
  const [selectedSport, setSelectedSport] = useState(null);
  const [publicSports, setPublicSports] = useState([]);
  const [customSports, setCustomSports] = useState([]);
  const [accessCode, setAccessCode] = useState('');

  // Football-specific fields
  const [numTeams, setNumTeams] = useState(8);
  const [draftType, setDraftType] = useState('Live');
  const [scoringSystem, setScoringSystem] = useState('Standard');

  // Messages
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [invitationCode, setInvitationCode] = useState('');

  // Email Invitation
  const [email, setEmail] = useState('');
  const [emailStatus, setEmailStatus] = useState('');

  // Fetch all public (non-custom) sports initially
  useEffect(() => {
    const fetchPublicSports = async () => {
      const { data, error } = await supabase
        .from('sports')
        .select('*')
        .eq('is_custom', false);

      if (!error) setPublicSports(data);
      else console.error("Error fetching public sports:", error);
    };
    fetchPublicSports();
  }, []);

  // Handle access code submission
  const handleAccessCodeSubmit = async (e) => {
    e.preventDefault();

    if (!accessCode.trim()) {
      setErrorMessage("Please enter a valid access code.");
      return;
    }

    // Fetch organization with the provided access code
    const { data: orgData, error: orgError } = await supabase
      .from('organizations')
      .select('organization_id, name')
      .eq('access_code', accessCode)
      .single();

    if (orgError || !orgData) {
      setErrorMessage("Invalid access code. Please try again.");
      return;
    }

    const orgId = orgData.organization_id;

    // Fetch custom sports associated with the organization
    const { data: customSportsData, error: sportsError } = await supabase
      .from('sports')
      .select('*')
      .eq('organization_id', orgId);

    if (!sportsError) {
      setCustomSports(customSportsData);
      setErrorMessage(''); // Clear error message if any
    } else {
      console.error("Error fetching custom sports:", sportsError);
      setErrorMessage("Failed to retrieve sports. Please try again.");
    }
  };

  // Handle league creation
  const handleCreateLeague = async () => {
    try {
      // Generate a random invitation code if not already set
      if (!invitationCode) {
        const randomCode = Math.random().toString(36).substring(2, 10).toUpperCase();
        setInvitationCode(randomCode);
      }
      
      // Insert the fantasy league
      const { data: leagueData, error: leagueError } = await supabase
        .from('fantasy_leagues')
        .insert([{ 
          league_name: leagueName,
          sport_id: selectedSport,
          owner_id: user.id,
          num_teams: numTeams,
          draft_type: draftType,
          scoring_system: scoringSystem,
          invitation_code: invitationCode || Math.random().toString(36).substring(2, 10).toUpperCase(),
        }])
        .select()
        .single();

      if (leagueError) {
        setErrorMessage("Failed to create league. Please try again.");
        console.error("Error creating league:", leagueError);
        return;
      }
      
      // Add the owner as a league member
      const { error: memberError } = await supabase
        .from('league_members')
        .insert([{
          league_id: leagueData.league_id,
          user_id: user.id,
          joined_at: new Date().toISOString()
        }]);
        
      if (memberError) {
        console.error("Error adding owner as member:", memberError);
        // Continue anyway as this is not critical
      }
      
      // Create a draft entry for the league
      const defaultDraftTime = new Date();
      defaultDraftTime.setDate(defaultDraftTime.getDate() + 7); // Default to 1 week from now
      
      const { error: draftError } = await supabase
        .from('league_drafts')
        .insert([{
          league_id: leagueData.league_id,
          draft_time: defaultDraftTime.toISOString(),
          status: 'Scheduled',
          draft_type: draftType,
          current_round: 1
        }]);
        
      if (draftError) {
        console.error("Error creating draft entry:", draftError);
        // Continue anyway as this is not critical
      }
      
      setSuccessMessage("Fantasy league created successfully!");

      // Store the invitation code in state to display it
      setInvitationCode(leagueData.invitation_code);

      // Optionally navigate to a different page or reset the form
      // setTimeout(() => navigate('/fantasy'), 3000);
    } catch (error) {
      console.error("Error creating league:", error);
      setErrorMessage("Failed to create league. Please try again.");
    }
  };

  // Proceed to next step
  const handleNextStep = () => {
    if (currentStep === 1) {
      if (!leagueName || !selectedSport) {
        setErrorMessage("Please enter a league name and select a sport.");
        return;
      }
      setErrorMessage('');
      const sportName = getSelectedSportName().toLowerCase();
      if (sportName.includes('football') || sportName.includes('nfl')) {
        setCurrentStep(2);
      } else {
        setCurrentStep(3); // Skip to confirmation if not football
      }
    } else if (currentStep === 2) {
      setCurrentStep(3);
    }
  };

  // Go back to previous step
  const handlePreviousStep = () => {
    setCurrentStep(currentStep - 1);
  };

  // Get selected sport name
  const getSelectedSportName = () => {
    const sport = [...publicSports, ...customSports].find(s => s.sport_id === selectedSport);
    return sport ? sport.sport_name : '';
  };

  // Handle sending email invitation
  const sendInvitationEmail = async (receiverEmail, invitationLink, leagueName) => {
    try {
      const response = await fetch('/api/sendInvitationEmail', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ receiverEmail, invitationLink, leagueName }),
      });

      if (!response.ok) {
        throw new Error('Failed to send invitation email');
      }

      const data = await response.json();
      console.log(data.message);
      setEmailStatus("Invitation email sent successfully!");
    } catch (error) {
      console.error('Error sending invitation email:', error.message);
      setEmailStatus("Failed to send email. Please try again.");
    }
  };

  const handleSendEmail = async () => {
    if (!email) {
      setEmailStatus("Please enter an email address.");
      return;
    }

    const invitationLink = `https://milkysports.com/join-league?code=${invitationCode}`;

    await sendInvitationEmail(email, invitationLink, leagueName);
  };

  return (
    <Container>
      <Title>Create Fantasy League</Title>

      {/* Step Indicators */}
      <StepIndicators>
        <Step className={currentStep >= 1 ? 'active' : ''}>1. League Details</Step>
        <Step className={currentStep >= 2 ? 'active' : ''}>2. { (getSelectedSportName().toLowerCase().includes('football') || getSelectedSportName().toLowerCase().includes('nfl')) ? 'Football Settings' : 'Settings' }</Step>
        <Step className={currentStep === 3 ? 'active' : ''}>3. Confirmation</Step>
      </StepIndicators>

      {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
      {successMessage && <SuccessMessage>{successMessage}</SuccessMessage>}

      {/* Step 1: League Details */}
      {currentStep === 1 && (
        <>
          {/* Access Code Submission */}
          <AccessCodeSection>
            <label>Have an Access Code for Custom Sports?</label>
            <form onSubmit={handleAccessCodeSubmit}>
              <AccessCodeInput>
                <input 
                  type="text" 
                  value={accessCode} 
                  onChange={(e) => setAccessCode(e.target.value)} 
                  placeholder="Enter access code" 
                />
                <button type="submit">Submit</button>
              </AccessCodeInput>
            </form>
          </AccessCodeSection>

          {/* League Details Form */}
          <LeagueForm>
            <FormGroup>
              <label>League Name:</label>
              <input 
                type="text" 
                value={leagueName} 
                onChange={(e) => setLeagueName(e.target.value)} 
                placeholder="Enter league name" 
              />
            </FormGroup>

            <FormGroup>
              <label>Select Sport:</label>
              <SportsContainer>
                {publicSports.map(sport => (
                  <SportBubble 
                    key={sport.sport_id} 
                    className={selectedSport === sport.sport_id ? 'selected' : ''}
                    onClick={() => setSelectedSport(sport.sport_id)}
                  >
                    {sport.sport_name}
                  </SportBubble>
                ))}
                {customSports.map(sport => (
                  <SportBubble 
                    key={sport.sport_id} 
                    className={selectedSport === sport.sport_id ? 'selected' : ''}
                    onClick={() => setSelectedSport(sport.sport_id)}
                  >
                    {sport.sport_name} (Custom)
                  </SportBubble>
                ))}
              </SportsContainer>
            </FormGroup>

            <ButtonGroup>
              <NextButton onClick={handleNextStep}>Next</NextButton>
            </ButtonGroup>
          </LeagueForm>
        </>
      )}

      {/* Step 2: Football Settings */}
      {currentStep === 2 && (
        <FootballSettings>
          <h2>Football League Settings</h2>
          <FormGroup>
            <label>Number of Teams:</label>
            <select value={numTeams} onChange={(e) => setNumTeams(parseInt(e.target.value))}>
              {[4, 6, 8, 10, 12, 14, 16].map(num => (
                <option key={num} value={num}>{num}</option>
              ))}
            </select>
          </FormGroup>

          <FormGroup>
            <label>Draft Type:</label>
            <select value={draftType} onChange={(e) => setDraftType(e.target.value)}>
              <option value="Live">Live Draft</option>
              <option value="Auto">Auto Draft</option>
              <option value="Snake">Snake Draft</option>
            </select>
          </FormGroup>

          <FormGroup>
            <label>Scoring System:</label>
            <select value={scoringSystem} onChange={(e) => setScoringSystem(e.target.value)}>
              <option value="Standard">Standard</option>
              <option value="PPR">PPR (Point Per Reception)</option>
              <option value="HalfPPR">Half PPR</option>
              <option value="Custom">Custom</option>
            </select>
          </FormGroup>

          <ButtonGroup>
            <BackButton onClick={handlePreviousStep}>Back</BackButton>
            <NextButton onClick={handleNextStep}>Next</NextButton>
          </ButtonGroup>
        </FootballSettings>
      )}

      {/* Step 3: Confirmation */}
      {currentStep === 3 && (
        <Confirmation>
          <h2>Confirm League Details</h2>
          <p><strong>League Name:</strong> {leagueName}</p>
          <p><strong>Sport:</strong> {getSelectedSportName()}</p>
          
          {(getSelectedSportName().toLowerCase().includes('football') || getSelectedSportName().toLowerCase().includes('nfl')) && (
            <>
              <p><strong>Number of Teams:</strong> {numTeams}</p>
              <p><strong>Draft Type:</strong> {draftType}</p>
              <p><strong>Scoring System:</strong> {scoringSystem}</p>
            </>
          )}

          {!invitationCode ? (
            <>
              <ButtonGroup>
                <BackButton onClick={handlePreviousStep}>Back</BackButton>
                <CreateButton onClick={handleCreateLeague}>Create League</CreateButton>
              </ButtonGroup>
            </>
          ) : (
            <InvitationSection>
              <h3>League Created Successfully!</h3>
              <p>Share this invitation code with others to join your league:</p>
              <div className="invitation-code">{invitationCode}</div>
              
              <h3>Or send an email invitation:</h3>
              <div className="email-form">
                <input 
                  type="email" 
                  value={email} 
                  onChange={(e) => setEmail(e.target.value)} 
                  placeholder="Enter email address" 
                />
                <button onClick={handleSendEmail}>Send Invitation</button>
              </div>
              {emailStatus && <p>{emailStatus}</p>}
            </InvitationSection>
          )}
        </Confirmation>
      )}
    </Container>
  );
};

export default CreateFantasyLeague;
