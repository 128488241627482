import React, { useState, useEffect } from "react";
import styled from "styled-components";

const MatchupsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  .week-selector {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 20px;
    select {
      padding: 8px;
      background-color: #2e2e3d;
      color: white;
      border: none;
      border-radius: 5px;
    }
    button {
      padding: 8px 15px;
      background-color: #007bff;
      color: white;
      border: none;
      border-radius: 5px;
      cursor: pointer;
      &:hover {
        background-color: #0056b3;
      }
    }
  }
  .matchups {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 15px;
  }
  .matchup-card {
    background-color: #2e2e3d;
    border-radius: 8px;
    padding: 15px;
    display: flex;
    flex-direction: column;
    .matchup-header {
      text-align: center;
      margin-bottom: 10px;
      h3 {
        color: #007bff;
        margin-bottom: 5px;
      }
      p {
        color: #aaa;
        font-size: 14px;
      }
    }
    .matchup-teams {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 15px 0;
      .team {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 45%;
        .team-name {
          font-weight: bold;
          margin-bottom: 5px;
          text-align: center;
        }
        .team-score {
          font-size: 24px;
          font-weight: bold;
          color: #007bff;
        }
      }
      .vs {
        font-size: 18px;
        color: #aaa;
      }
    }
    .matchup-status {
      text-align: center;
      font-size: 14px;
      color: #aaa;
      margin-top: 10px;
    }
  }
`;

const Matchups = ({ supabase, league_id }) => {
  const [matchups, setMatchups] = useState([]);
  const [currentWeek, setCurrentWeek] = useState(1);
  const [maxWeeks] = useState(17);

  useEffect(() => {
    if (!league_id) return;
    const fetchMatchups = async () => {
      const { data, error } = await supabase
        .from("league_matchups")
        .select("*, home_team:home_team_id(id, team_name), away_team:away_team_id(id, team_name)")
        .eq("league_id", league_id)
        .eq("week", currentWeek)
        .order("id", { ascending: true });
      if (!error) setMatchups(data || []);
    };
    fetchMatchups();
  }, [supabase, league_id, currentWeek]);

  const calculateWeeklyScores = async (week) => {
    // Simplified demo implementation.
    alert(`Scores for week ${week} calculated (demo).`);
  };

  return (
    <div>
      <h2>Weekly Matchups</h2>
      <MatchupsContainer>
        <div className="week-selector">
          <label>Week:</label>
          <select
            value={currentWeek}
            onChange={(e) => setCurrentWeek(parseInt(e.target.value))}
          >
            {Array.from({ length: maxWeeks }, (_, i) => i + 1).map((week) => (
              <option key={week} value={week}>
                Week {week}
              </option>
            ))}
          </select>
          <button onClick={() => calculateWeeklyScores(currentWeek)}>
            Calculate Scores
          </button>
        </div>
        <div className="matchups">
          {matchups.length > 0 ? (
            matchups.map((m) => (
              <div key={m.id} className="matchup-card">
                <div className="matchup-header">
                  <h3>Week {m.week}</h3>
                  <p>{m.is_complete ? "Final" : "Upcoming"}</p>
                </div>
                <div className="matchup-teams">
                  <div className="team">
                    <div className="team-name">
                      {m.home_team?.team_name || "Home Team"}
                    </div>
                    <div className="team-score">{m.home_score}</div>
                  </div>
                  <div className="vs">vs</div>
                  <div className="team">
                    <div className="team-name">
                      {m.away_team?.team_name || "Away Team"}
                    </div>
                    <div className="team-score">{m.away_score}</div>
                  </div>
                </div>
                <div className="matchup-status">
                  {m.is_complete
                    ? `Winner: ${
                        m.home_score > m.away_score
                          ? m.home_team?.team_name
                          : m.away_team?.team_name
                      }`
                    : "Not yet played"}
                </div>
              </div>
            ))
          ) : (
            <p>No matchups scheduled for Week {currentWeek}</p>
          )}
        </div>
      </MatchupsContainer>
    </div>
  );
};

export default Matchups;
