import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useUser } from './UserContext';
import { createClient } from '@supabase/supabase-js';
import styled from 'styled-components';

// Styled components
const Container = styled.div`
  max-width: 800px;
  margin: 20px auto;
  padding: 20px;
  color: #fff;
  background-color: transparent;
  box-shadow: none;
  border-radius: 0;
  
  @media (max-width: 768px) {
    padding: 15px;
  }
`;

const Header = styled.h1`
  text-align: center;
  margin-bottom: 30px;
  color: #007bff;
  font-size: 28px;
  font-weight: bold;
  
  @media (max-width: 768px) {
    font-size: 24px;
  }
`;

const StepIndicators = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
`;

const Step = styled.div`
  flex: 1;
  text-align: center;
  padding: 10px 0;
  border-bottom: 3px solid #444;
  color: #888;
  font-weight: bold;
  font-size: 14px;
  text-transform: uppercase;
  
  &.active {
    border-color: #007bff;
    color: #007bff;
  }
  
  @media (max-width: 768px) {
    font-size: 12px;
  }
`;

const FormGroup = styled.div`
  margin-bottom: 20px;
  
  label {
    display: block;
    font-size: 16px;
    margin-bottom: 8px;
    color: #ccc;
  }
  
  input[type="text"] {
    width: 100%;
    padding: 12px;
    border: 1px solid #444;
    border-radius: 5px;
    background-color: #1a1a1a;
    color: #fff;
    font-size: 16px;
    transition: border-color 0.3s ease;
    
    &:focus {
      border-color: #007bff;
      outline: none;
    }
    
    &::placeholder {
      color: #888;
    }
  }
`;

const ButtonGroup = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
`;

const Button = styled.button`
  padding: 12px 20px;
  font-size: 16px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
  margin-left: 10px;
`;

const NextButton = styled(Button)`
  background-color: #007bff;
  color: #fff;
  
  &:hover {
    background-color: #0056b3;
  }
`;

const BackButton = styled(Button)`
  background-color: #6c757d;
  color: #fff;
  
  &:hover {
    background-color: #5a6268;
  }
`;

const AddButton = styled(Button)`
  background-color: #28a745;
  color: #fff;
  
  &:hover {
    background-color: #218838;
  }
`;

const SuccessMessage = styled.div`
  color: #28a745;
  font-size: 16px;
  margin-top: 20px;
  text-align: center;
`;

const ErrorMessage = styled.div`
  color: #dc3545;
  font-size: 16px;
  margin-top: 20px;
  text-align: center;
`;

const SportSection = styled.div`
  margin-bottom: 30px;
  padding: 15px;
  border: 1px solid #444;
  border-radius: 5px;
  background-color: rgba(0, 0, 0, 0.2);
`;

const AddPlayers = styled.div`
  .form-group {
    margin-bottom: 15px;
  }
  
  button {
    margin-top: 10px;
  }
`;

const Confirmation = styled.div`
  text-align: center;
  padding: 20px;
  
  h2 {
    font-size: 22px;
    margin-bottom: 20px;
    color: #007bff;
  }
  
  p, h3, h4 {
    color: #ccc;
    margin-bottom: 10px;
  }
  
  ul {
    list-style: none;
    padding: 0;
    margin: 0;
    
    li {
      margin: 5px 0;
      color: #bbb;
    }
  }
`;

const supabase = createClient(
  process.env.REACT_APP_SUPABASE_URL,
  process.env.REACT_APP_SUPABASE_ANON_KEY
);

const CreateOrganization = () => {
  const { user } = useUser();
  const navigate = useNavigate();

  const [currentStep, setCurrentStep] = useState(1);
  const [orgName, setOrgName] = useState('');
  const [accessCode, setAccessCode] = useState('');
  const [sports, setSports] = useState([{ sportName: '', teams: [''] }]);
  const [players, setPlayers] = useState({});
  const [playerInputs, setPlayerInputs] = useState({}); // New state for individual inputs
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(false);

  const handleNextStep = () => {
    if (currentStep === 1 && !orgName.trim()) {
      setErrorMessage('Organization Name is required.');
      return;
    }
    if (currentStep === 2 && sports.some((sport) => !sport.sportName.trim())) {
      setErrorMessage('Each sport must have a name.');
      return;
    }
    setErrorMessage('');
    setCurrentStep(currentStep + 1);
  };

  const handlePreviousStep = () => {
    setErrorMessage('');
    setCurrentStep(currentStep - 1);
  };

  const handleSportChange = (index, value) => {
    const updatedSports = [...sports];
    updatedSports[index].sportName = value;
    setSports(updatedSports);
  };

  const handleAddSport = () => {
    setSports([...sports, { sportName: '', teams: [''] }]);
  };

  const handleTeamChange = (sportIndex, teamIndex, value) => {
    const updatedSports = [...sports];
    updatedSports[sportIndex].teams[teamIndex] = value;
    setSports(updatedSports);
  };

  const handleAddTeam = (sportIndex) => {
    const updatedSports = [...sports];
    updatedSports[sportIndex].teams.push('');
    setSports(updatedSports);
  };

  const handlePlayerInputChange = (teamKey, value) => {
    setPlayerInputs((prevInputs) => ({
      ...prevInputs,
      [teamKey]: value,
    }));
  };

  const handleAddPlayer = (teamKey) => {
    if (!playerInputs[teamKey]?.trim()) return;
    setPlayers((prevPlayers) => ({
      ...prevPlayers,
      [teamKey]: [...(prevPlayers[teamKey] || []), playerInputs[teamKey].trim()],
    }));
    setPlayerInputs((prevInputs) => ({
      ...prevInputs,
      [teamKey]: '',
    }));
  };

  const handleCreateOrganization = async () => {
    setLoading(true);
    try {
      const { data: orgData, error: orgError } = await supabase
        .from('organizations')
        .insert([{ name: orgName, owner_id: user.id, access_code: accessCode }])
        .select()
        .single();

      if (orgError || !orgData) throw new Error('Failed to create organization.');

      const organizationId = orgData.organization_id;

      for (const sport of sports) {
        const { data: sportData, error: sportError } = await supabase
          .from('sports')
          .insert([{ organization_id: organizationId, sport_name: sport.sportName }])
          .select()
          .single();

        if (sportError || !sportData) throw new Error('Failed to create sport.');

        const sportId = sportData.sport_id;

        for (const teamName of sport.teams) {
          const { data: teamData, error: teamError } = await supabase
            .from('orgteams')
            .insert([{ organization_id: organizationId, sport_id: sportId, name: teamName }])
            .select()
            .single();

          if (teamError || !teamData) throw new Error('Failed to create team.');

          const teamId = teamData.team_id;
          const teamKey = `sport-${sports.indexOf(sport)}-team-${sport.teams.indexOf(teamName)}`;
          const teamPlayers = players[teamKey] || [];
          for (const playerName of teamPlayers) {
            const { error: playerError } = await supabase
              .from('orgteamplayers')
              .insert([{ organization_id: organizationId, team_id: teamId, player_name: playerName }]);

            if (playerError) throw new Error('Failed to add players.');
          }
        }
      }

      setSuccessMessage('Organization created successfully!');
      setTimeout(() => navigate('/fantasy'), 3000);
    } catch (error) {
      setErrorMessage(error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container>
      <Header>Create Your Organization</Header>

      <StepIndicators>
        <Step className={currentStep >= 1 ? 'active' : ''}>1. Organization Details</Step>
        <Step className={currentStep >= 2 ? 'active' : ''}>2. Sports & Teams</Step>
        <Step className={currentStep === 3 ? 'active' : ''}>3. Add Players</Step>
        <Step className={currentStep === 4 ? 'active' : ''}>4. Confirmation</Step>
      </StepIndicators>

      {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
      {successMessage && <SuccessMessage>{successMessage}</SuccessMessage>}

      {currentStep === 1 && (
        <div>
          <FormGroup>
            <label>Organization Name:</label>
            <input
              type="text"
              value={orgName}
              onChange={(e) => setOrgName(e.target.value)}
              placeholder="Enter your organization name"
              required
            />
          </FormGroup>
          <FormGroup>
            <label>Access Code (optional):</label>
            <input
              type="text"
              value={accessCode}
              onChange={(e) => setAccessCode(e.target.value)}
              placeholder="Enter a unique code for private sports"
            />
          </FormGroup>
          <ButtonGroup>
            <NextButton onClick={handleNextStep}>Next</NextButton>
          </ButtonGroup>
        </div>
      )}

      {currentStep === 2 && (
        <>
          {sports.map((sport, sportIndex) => (
            <SportSection key={sportIndex}>
              <FormGroup>
                <label>Sport {sportIndex + 1} Name:</label>
                <input
                  type="text"
                  value={sport.sportName}
                  onChange={(e) => handleSportChange(sportIndex, e.target.value)}
                  placeholder="Enter sport name"
                />
              </FormGroup>
              {sport.teams.map((team, teamIndex) => (
                <FormGroup key={teamIndex}>
                  <label>Team {teamIndex + 1} Name:</label>
                  <input
                    type="text"
                    value={team}
                    onChange={(e) => handleTeamChange(sportIndex, teamIndex, e.target.value)}
                    placeholder="Enter team name"
                  />
                </FormGroup>
              ))}
              <AddButton onClick={() => handleAddTeam(sportIndex)}>Add Another Team</AddButton>
            </SportSection>
          ))}
          <AddButton onClick={handleAddSport}>Add Another Sport</AddButton>
          <ButtonGroup>
            <BackButton onClick={handlePreviousStep}>Back</BackButton>
            <NextButton onClick={handleNextStep}>Next</NextButton>
          </ButtonGroup>
        </>
      )}

      {currentStep === 3 && (
        <AddPlayers>
          {sports.map((sport, sportIndex) => (
            sport.teams.map((team, teamIndex) => {
              const teamKey = `sport-${sportIndex}-team-${teamIndex}`;
              return (
                <FormGroup key={teamKey} className="form-group">
                  <label>Add Player to Team {team}:</label>
                  <input
                    type="text"
                    value={playerInputs[teamKey] || ''}
                    onChange={(e) => handlePlayerInputChange(teamKey, e.target.value)}
                    placeholder="Enter player name"
                  />
                  <AddButton
                    onClick={() => handleAddPlayer(teamKey)}
                  >
                    Add Player
                  </AddButton>
                  {players[teamKey] && players[teamKey].length > 0 && (
                    <div>
                      <h4>Added Players:</h4>
                      <ul>
                        {players[teamKey].map((player, idx) => (
                          <li key={idx}>{player}</li>
                        ))}
                      </ul>
                    </div>
                  )}
                </FormGroup>
              );
            })
          ))}
          <ButtonGroup>
            <BackButton onClick={handlePreviousStep}>Back</BackButton>
            <NextButton onClick={handleNextStep}>Next</NextButton>
          </ButtonGroup>
        </AddPlayers>
      )}

      {currentStep === 4 && (
        <Confirmation>
          <h2>Review Your Organization</h2>
          <p><strong>Organization Name:</strong> {orgName}</p>
          <p><strong>Access Code:</strong> {accessCode || 'N/A'}</p>
          {sports.map((sport, sportIndex) => (
            <div key={sportIndex}>
              <h3>{sport.sportName}</h3>
              <h4>Teams:</h4>
              <ul>
                {sport.teams.map((team, teamIndex) => {
                  const teamKey = `sport-${sportIndex}-team-${teamIndex}`;
                  return (
                    <li key={teamIndex}>
                      {team}
                      {players[teamKey] && players[teamKey].length > 0 && (
                        <div>
                          <h4>Players:</h4>
                          <ul>
                            {players[teamKey].map((player, idx) => (
                              <li key={idx}>{player}</li>
                            ))}
                          </ul>
                        </div>
                      )}
                    </li>
                  );
                })}
              </ul>
            </div>
          ))}
          <ButtonGroup>
            <BackButton onClick={handlePreviousStep}>Back</BackButton>
            <NextButton onClick={handleCreateOrganization} disabled={loading}>
              {loading ? 'Creating...' : 'Create Organization'}
            </NextButton>
          </ButtonGroup>
        </Confirmation>
      )}
    </Container>
  );
};

export default CreateOrganization;
