import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { createClient } from '@supabase/supabase-js';
import { useUser } from './UserContext';
import styled from 'styled-components';
import backgroundImage from './images/vintagefb.png';

// Styled components
const LoginWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: url(${backgroundImage}) no-repeat center center/cover;
`;

const LoginFormContainer = styled.div`
  background-color: rgba(0, 0, 0, 0.8);
  padding: 30px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  text-align: center;
  width: 100%;
  max-width: 400px;
`;

const Title = styled.h2`
  color: #fff;
  margin-bottom: 20px;
  font-size: 28px;
  text-align: center;
  border-bottom: 2px solid #007bff;
  padding-bottom: 10px;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 15px;
  align-items: center;
`;

const Label = styled.label`
  color: #ddd;
  font-size: 14px;
  align-self: flex-start;
`;

const Input = styled.input`
  width: 94%;
  padding: 12px;
  border: 1px solid #555;
  border-radius: 4px;
  background-color: #333;
  color: white;
  font-size: 14px;
`;

const Button = styled.button`
  width: 100%;
  padding: 12px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  
  &:hover {
    background-color: #0056b3;
  }
`;

const SocialLoginButton = styled(Button)`
  margin-top: 10px;
  background-color: ${props => props.provider === 'google' ? '#DB4437' : '#000'};
  
  &:hover {
    background-color: ${props => props.provider === 'google' ? '#C53929' : '#333'};
  }
`;

const LinkText = styled.p`
  margin-top: 15px;
  font-size: 14px;
  color: #007bff;
  text-align: center;
  
  a {
    color: #007bff;
    text-decoration: none;
    
    &:hover {
      text-decoration: underline;
    }
  }
`;

const ErrorMessage = styled.p`
  color: red;
  margin-bottom: 10px;
  font-size: 14px;
`;

const supabase = createClient(
  process.env.REACT_APP_SUPABASE_URL,
  process.env.REACT_APP_SUPABASE_ANON_KEY
);

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const { setUser } = useUser();
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const { data: authUser, error } = await supabase.auth.signInWithPassword({
        email,
        password,
      });

      if (error) throw error;

      if (!authUser.user.email_confirmed_at) {
        setErrorMessage('Please verify your email before logging in.');
        return;
      }

      // Fetch profile data
      const { data: profile, error: profileError } = await supabase
        .from('profiles')
        .select('*')
        .eq('id', authUser.user.id)
        .single();

      if (profileError) throw profileError;

      // Set the user in context with profile information
      setUser({
        id: authUser.user.id,
        email: authUser.user.email,
        username: profile.username,
        profile,
      });

      // Redirect after login
      const redirectPath = localStorage.getItem('redirectAfterLogin');
      if (redirectPath) {
        localStorage.removeItem('redirectAfterLogin');
        navigate(redirectPath);
      } else {
        navigate('/');
      }
    } catch (error) {
      setErrorMessage(error.message || 'An error occurred. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleSocialLogin = async (provider) => {
    try {
      const { error } = await supabase.auth.signInWithOAuth({
        provider,
      });

      if (error) throw error;

      navigate('/');
    } catch (error) {
      setErrorMessage(error.message || 'Social login failed.');
    }
  };

  useEffect(() => {
    if (errorMessage) {
      const timer = setTimeout(() => setErrorMessage(''), 5000);
      return () => clearTimeout(timer);
    }
  }, [errorMessage]);

  return (
    <LoginWrapper>
      <LoginFormContainer>
        <Title>Login</Title>
        {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
        <Form onSubmit={handleLogin}>
          <Label>Email:</Label>
          <Input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
            disabled={isLoading}
          />
          <Label>Password:</Label>
          <Input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
            disabled={isLoading}
          />
          <Button type="submit" disabled={isLoading}>
            {isLoading ? 'Logging in...' : 'Login'}
          </Button>
        </Form>
        <SocialLoginButton
          type="button"
          provider="google"
          onClick={() => handleSocialLogin('google')}
        >
          Login with Google
        </SocialLoginButton>
        <SocialLoginButton
          type="button"
          provider="apple"
          onClick={() => handleSocialLogin('apple')}
        >
          Login with Apple
        </SocialLoginButton>
        <LinkText>
          Don't have an account? <Link to="/create-account">Register</Link>
        </LinkText>
      </LoginFormContainer>
    </LoginWrapper>
  );
};

export default Login;
